import { createApp, defineComponent } from "vue";
import { createPinia } from "pinia";

import App from "@/App.vue";
import router from "@/router/router";
import store from "@/store";

import mainCore from "@/mainCore";
import { initApiService } from "@/services/ApiService.js";

import { initLocalStorageManager } from "@/LocalStorageManager.js";
import routerPath from "@/router/routerPath.js";

const isNotRedirectUrl =
  window.location.pathname === routerPath.LOGIN ||
  window.location.pathname === routerPath.LOGIN_ADMIN;
if (!isNotRedirectUrl) {
  //console.log(to.path);
  sessionStorage.setItem("purchaseReirect", window.location.pathname);
} else {
  //console.log("여긴저장안해");
}
const pinia = createPinia();

const app = createApp(App);

app.use(router);
app.use(store);
app.use(pinia);

app.mount("#app");

//app.config.productionTip = false;
app.config.globalProperties.$isLocal = process.env.NODE_ENV === "local";
app.config.globalProperties.$isDevlop = process.env.NODE_ENV === "development";
app.config.globalProperties.$isProd = process.env.NODE_ENV === "production";
app.config.globalProperties.$isKEP = true;

app.config.warnHandler = (msg, instance, trace) => {
  if (msg.indexOf("ATTR_FALSE_VALUE") > -1) {
    //임시막음
    return null;
  }
};

app.use(mainCore);
app.use(initApiService);
app.use(initLocalStorageManager);

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    $isLocal: boolean;
    $isDevlop: boolean;
    $isProd: boolean;
    $isKEP: boolean;
  }
}
