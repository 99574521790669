const routerPath = {
  LOGIN: "/login",
  LOGIN_ADMIN: "/vmffotvharhksfl",
  CAS_LOGIN_SUCCESS: "/login/success",
  MY_INFO: "/myinfo",
  JOIN: "/join",
  JOIN_INQUIRY: "/joininquiry",

  HOME: "/",
  //기본정보
  INFO: "/info",
  ITEM: "/info/item", //품목관리
  ITEM_LIST: "/info/item/list",
  ITEM_VIEW: "/info/item/view",
  ITEM_WRITE: "/info/item/write",
  ITEM_MODIFY: "/info/item/modify",
  PARTNER: "/info/partner", //파트너관리
  PARTNER_LIST: "/info/partner/list",
  PARTNER_VIEW: "/info/partner/view",
  PARTNER_WRITE: "/info/partner/write",
  PARTNER_MODIFY: "/info/partner/modify",
  PERFORMANCE: "/info/performance", // 수행도평가 관리
  PERFORMANCE_LIST: "/info/performance/list",
  PERFORMANCE_VIEW: "/info/performance/view",
  PERFORMANCE_WRITE: "/info/performance/write",
  PERFORMANCE_MODIFY: "/info/performance/modify",
  //구매관리
  //MANAGE: '/manage',
  DRAFT: "/draft", //기안관리
  DRAFT_LIST: "/draft/list",
  DRAFT_VIEW: "/draft/view",
  DRAFT_WRITE: "/draft/write",
  DRAFT_MODIFY: "/draft/modify",
  INCREASE: "/increase",
  INCREASE_LIST: "/increase/list",
  INCREASE_VIEW: "/increase/view",
  INCREASE_WRITE: "/increase/write",
  INCREASE_MODIFY: "/increase/modify",
  PURCHASE: "/purchase", //구매요청
  PURCHASE_LIST: "/purchase/list",
  PURCHASE_VIEW: "/purchase/view",
  PURCHASE_WRITE: "/purchase/write",
  PURCHASE_MODIFY: "/purchase/modify",
  ESTIMATE: "/estimate", //견적관리
  ESTIMATE_LIST: "/estimate/list",
  ESTIMATE_VIEW: "/estimate/view",
  ESTIMATE_WRITE: "/estimate/write",
  ESTIMATE_MODIFY: "/estimate/modify",
  ESTIMATE_RE: "/estimate/re",
  ANNOUNCE: "/announces", //공고관리
  ANNOUNCE_LIST: "/announces/list",
  ANNOUNCE_VIEW: "/announces/view",
  ANNOUNCE_WRITE: "/announces/write",
  ANNOUNCE_MODIFY: "/announces/modify",
  BID: "/bid", //입찰관리
  BID_LIST: "/bid/list",
  BID_VIEW: "/bid/view",
  BID_WRITE: "/bid/write", //  BID_MODIFY: '/bid/modify',

  EXPENDITURE: "/expenditure", //기안
  EXPENDITURE_LIST: "/expenditure/list",
  EXPENDITURE_VIEW: "/expenditure/view",
  EXPENDITURE_WRITE: "/expenditure/write",
  EXPENDITURE_MODIFY: "/expenditure/modify",

  //발주/계약
  CONTRACT: "/contract", //계약
  CONTRACT_LIST: "/contract/list",
  CONTRACT_VIEW: "/contract/view",
  CONTRACT_WRITE: "/contract/write",
  CONTRACT_MODIFY: "/contract/modify",
  CONTRACT_RENEWAL: "/contract/renewal", //변경계약
  ORDER: "/order", //발주
  ORDER_LIST: "/order/list",
  ORDER_VIEW: "/order/view",
  ORDER_WRITE: "/order/write",
  ORDER_MODIFY: "/order/modify",
  ORDER_RENEWAL: "/order/renewal", //변경발주

  //마감/정산
  INSPECT: "/inspect", //검수
  INSPECT_LIST: "/inspect/list",
  INSPECT_VIEW: "/inspect/view",
  INSPECT_WRITE: "/inspect/write",

  SETTLEMENT: "/settlement", //정산서
  SETTLEMENT_LIST: "/settlement/list",
  SETTLEMENT_VIEW: "/settlement/view",
  SETTLEMENT_WRITE: "/settlement/write",
  SETTLEMENT_MODIFY: "/settlement/modify",

  //  INSPECT_MODIFY: '/inspect/modify',
  TRANSACTIONREPORT: "/transactionreport", //거래명세서
  TRANSACTIONREPORT_LIST: "/transactionreport/list",
  TRANSACTIONREPORT_VIEW: "/transactionreport/view",
  TRANSACTIONREPORT_WRITE: "/transactionreport/write",
  TRANSACTIONREPORT_MODIFY: "/transactionreport/modify",

  //전자결재
  PAYMENT: "/payment",
  // PAYMENT_WRITE: '/payment/write',
  // PAYMENT_MODIFY: '/payment/modify', //작성
  // PAYMENT_MY: '/payment/my',
  // PAYMENT_APPROVAL: '/payment/approval',
  // PAYMENT_CC: '/payment/cc',
  // PAYMENT_SETTING: '/payment/setting', //결재선 세팅
  // PAYMENT_SETTING_LIST: '/payment/setting/list',
  // PAYMENT_SETTING_WRITE: '/payment/setting/write',
  // PAYMENT_SETTING_MODIFY: '/payment/setting/modify',
  // PAYMENT_GUIDE: '/payment/guide', //가이드 세팅
  // PAYMENT_GUIDE_LIST: '/payment/guide/list',
  // PAYMENT_GUIDE_WRITE: '/payment/guide/write',
  // PAYMENT_GUIDE_MODIFY: '/payment/guide/modify',

  //통계
  // STATISTICS: '/statistics',
  // STATISTICS_ORDER: '/statistics/order',
  // STATISTICS_PURCHASE: '/statistics/purchase',

  //시스템관리
  SYSTEM: "/system",
  SYSTEM_CODE: "/system/code", //코드관리
  SYSTEM_CODE_LIST: "/system/code/list",
  SYSTEM_CODE_WRITE: "/system/code/write",
  SYSTEM_CODE_MODIFY: "/system/code/modify",
  SYSTEM_HANDLED: "/system/handle", //취급품목
  SYSTEM_CATEGORY: "/system/category", //카테고리
  SYSTEM_TEMPLATE: "/system/template", //템플릿
  SYSTEM_TEMPLATE_LIST: "/system/template/list",
  SYSTEM_TEMPLATE_WRITE: "/system/template/write",
  SYSTEM_TEMPLATE_MODIFY: "/system/template/modify",

  SYSTEM_PERFORMANCE: "/system/performance", //업체(평가)관리
  SYSTEM_PERFORMANCE_WRITE: "/system/performance/write",
  SYSTEM_PERFORMANCE_MODIFY: "/system/performance/modify",
  SYSTEM_PERFORMANCE_SETTING: "/system/performance/setting", //업체(평가) 생성
  SYSTEM_PERFORMANCE_PRESENT: "/system/performance/present", //현황
  SYSTEM_PERFORMANCE_RESULT: "/system/performance/result", //결과

  SYSTEM_DIRECTOR: "/system/directors", //담당자관리
  SYSTEM_DIRECTOR_LIST: "/system/directors/list",
  SYSTEM_DIRECTOR_WRITE: "/system/directors/write",
  SYSTEM_DIRECTOR_MODIFY: "/system/directors/modify",
  SYSTEM_MENU: "/system/menu", //메뉴관리
  SYSTEM_MENU_LIST: "/system/menu/list",
  SYSTEM_MENU_WRITE: "/system/menu/write",
  SYSTEM_MENU_MODIFY: "/system/menu/modify",
  SYSTEM_APPROVALLINE: "/system/approvalline", //결재선관리
  SYSTEM_APPROVALLINE_LIST: "/system/approvalline/list",
  SYSTEM_APPROVALLINE_VIEW: "/system/approvalline/view",
  SYSTEM_APPROVALLINE_WRITE: "/system/approvalline/write",
  SYSTEM_APPROVALLINE_MODIFY: "/system/approvalline/modify",

  SYSTEM_NOTICE: "/system/notice", //공지사항
  SYSTEM_NOTICE_LIST: "/system/notice/list",
  SYSTEM_NOTICE_VIEW: "/system/notice/view",
  SYSTEM_NOTICE_WRITE: "/system/notice/write",
  SYSTEM_NOTICE_MODIFY: "/system/notice/modify",

  //etc
  LOGIN_DIRECTORS: "/login/vmffotvharhksfl",
  // JOIN_DIRECTORS : '/join/directors',???

  LOGIN_PARTNER: "/login/partner",
  JOIN_PARTNER: "/join/partner",
};

export default routerPath;
