import Vue from "vue";
import { parseJwt } from "@/utils/jwtUtils";
import { idWithName } from "@/filters/filters";

class LocalStorageManager {
  static instance;
  ITEM_NAME_USER_DATA = "userData";
  LOGIN_ID = "loginId";
  constructor() {
    this.app = null;
  }

  static get shared() {
    if (this.instance) {
      return this.instance;
    }

    this.instance = new LocalStorageManager();
    return this.instance;
  }

  init(app) {
    this.app = app;
  }

  // 신용 평가 자동 동기화 여부.
  getIsCreditAutoSync() {
    //임시 주석
    return false;
  }

  // 신규 등록 프로세스 가능 여부.
  getCanNewWriteProcess() {
    return !this.getIsInspect() && !this.getIsRollUser() && !this.getIsFinance();
  }

  // 구매신청서 노출 여부. 네이밍 한번에 변경 예정.
  getIsShowPurchaseRequest() {
    return true; //this.getIsKe() || this.getIsInspect();
  }

  // 신용평가등급 - 점수 형태로 표기
  getIsCreditEvaluationByPointType() {
    return this.getIsKe();
  }

  // 거래처 평가 가능 여부
  getCanEvaluatePartner() {
    return true;
  }

  // 템플릿 사용 여부
  getUseTemplate() {
    return this.getIsKakao() || this.getIsKe();
  }

  // 아이디 저장 체크.
  getLoginId() {
    const str = localStorage.getItem(this.LOGIN_ID);
    return str;
  }

  setLoginId(strLoginId) {
    localStorage.setItem(this.LOGIN_ID, strLoginId);
  }

  removeLoginId() {
    localStorage.removeItem(this.LOGIN_ID);
  }

  // k-pick 관리자 페이지에서 전자결재 페이지로 리다이렉트 필요 여부
  // getIsRedirectToApproval() {
  //   return this.getIsRollUser();
  // }

  getUserData() {
    const str = localStorage.getItem(this.ITEM_NAME_USER_DATA);
    if (!str) {
      return null;
    }
    const obj = JSON.parse(str);
    return obj;
  }

  setUserData(objUserData) {
    const strData = JSON.stringify(objUserData);
    localStorage.setItem(this.ITEM_NAME_USER_DATA, strData);
    // console.log("==========");
    // console.log(objUserData);
    // console.log(strData);
    // console.log("==========");
    // this.getTokenData();
  }

  getTokenData() {
    const userData = this.getUserData();
    if (!userData) return null;

    const { token } = userData;

    if (!token) return null;

    const objToken = parseJwt(token);

    // console.log("tokenData :>> ", objToken);
    return objToken;
  }

  getIsAdmin() {
    const tokenData = this.getTokenData();
    if (!tokenData) return false;
    const { aud } = tokenData;
    return aud && aud !== "ROLE_PARTNER";
  }

  getIsFinance() {
    const tokenData = this.getTokenData();
    if (!tokenData) return false;
    const { aud } = tokenData; 
    this.app.config.globalProperties.$IsFinance = aud && aud === "ROLE_FINANCE";
    return aud && aud === "ROLE_FINANCE";
  }

  getIsInspect() {
    const tokenData = this.getTokenData();
    if (!tokenData) return false;
    const { aud } = tokenData;
    this.app.config.globalProperties.$isInspect = aud && aud === "ROLE_INSPECT";
   
    return aud && aud === "ROLE_INSPECT";
  }
  getIsUser() {
    const tokenData = this.getTokenData();
    if (!tokenData) return false;
    const { aud } = tokenData;
    this.app.config.globalProperties.$isUser = aud && aud === "ROLE_USER";
    return aud && aud === "ROLE_USER";
  }
  getIsSuper() {
    const tokenData = this.getTokenData();
    if (!tokenData) return false;

    const { aud } = tokenData;
    return aud && aud === "ROLE_SUPER";
  }

  getIsPartner() {
    const tokenData = this.getTokenData();
    if (!tokenData) return false;

    const { aud } = tokenData;
    this.app.config.globalProperties.$isPartner = aud && aud === "ROLE_PARTNER";
    return aud && aud === "ROLE_PARTNER";
  }
  hasAdmin() {
    const tokenData = this.getTokenData();
    if (!tokenData) return false;

    const { aud } = tokenData;
    
    this.app.config.globalProperties.$isAdmin =
      aud && (aud.indexOf("ROLE_SUPER") > -1 || aud.indexOf("ROLE_ADMIN") > -1);

    return aud && (aud.indexOf("ROLE_SUPER") > -1 || aud.indexOf("ROLE_ADMIN") > -1);
  }

  getIsRollUser() {
    const tokenData = this.getTokenData();

    // if(!tokenData) {
    //   const userData = this.getUserData();
    //   const { aud } = userData;

    //   return aud && aud.indexOf('ROLE_USER') === -1;

    // }
    const { aud } = tokenData;

    return aud && aud.indexOf("ROLE_USER") > -1;
  }

  assignUserData(objNew) {
    const userData = this.getUserData();

    if (!userData) return;

    Object.assign(userData, objNew);

    this.setUserData(userData);
  }

  // 검수 담당자 여부.
  // getIsInspect() {
  //   const userData = this.getUserData();
  //   if (!userData || !userData.inspectYn) return false;

  //   return true;
  // }
  // getIsSkill() {
  //   const userData = this.getUserData();
  //   if (!userData || !userData.skillYn) return false;

  //   return true;
  // }

  // 검토 상태 계정 여부.
  getIsReviewStatus() {
    const userData = this.getUserData();
    if (!userData) return false;

    return userData.status === "N";
  }

  changeToken(newToken, newRefreshTokenToken) {
    const userData = this.getUserData();
    if (!userData) return;

    userData.token = newToken;
    userData.refreshToken = newRefreshTokenToken;

    this.setUserData(userData);
  }

  clear() {
    localStorage.removeItem(this.ITEM_NAME_USER_DATA);
    // localStorage.removeItem( this.IS_ADMIN );
    // localStorage.clear();
  }

  getUserLoginIdWithName() {
    const userData = this.getUserData();
    if (!userData) return "";

    // const ret = Vue.options.filters.idWithName(userData.username, userData.name);
    // return ret;
    return idWithName(userData.username, userData.name);
  }
}

export const initLocalStorageManager = {
  install(app) {
    LocalStorageManager.shared.init(app);
  },
};

export default LocalStorageManager;
