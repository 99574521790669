import { App as Application } from "vue";

import BodyScrollLock from "@/plugins/bodyScrollLock";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import ko from "element-plus/lib/locale/lang/ko";
import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";
import _ from "lodash";
import moment from "moment-business-days";
import vClickOutside from "click-outside-vue3";

import VuePdf from "vue3-pdfjs";

import filters from "@/filters/index";
import axios from "@/plugins/axios";
import apiPath from "@/services/apiPath.js";

import routerPath from "@/router/routerPath.js";
import paymentRouterPath from "@/router/paymentRouterPath.js";

import useToast, { toast } from "@/plugins/toast.js";
import { ALERT_ACTION } from "@/store/modules/alert/action";
import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    $routerPath: any;
    $paymentRouterPath: any;
    $apiPath: any;
    $_: any;
    _: any;
    moment: any;
    $windowOpen: any;
    $tabOpen: any;
    $assetsPath: string;
    $ALERT_ACTION: any;
    $PROGRESS_START_ACTION: any;
    $PROGRESS_END_ACTION: any;
  }
}

export default {
  install(app: Application) {
    app.config.globalProperties.$routerPath = routerPath;
    app.config.globalProperties.$paymentRouterPath = paymentRouterPath;
    app.config.globalProperties.$apiPath = apiPath;
    app.config.globalProperties.moment = moment;
    app.config.globalProperties.$windowOpen = function (path: string, option = "") {
      if (!path) {
        alert("경로가 올바르지 않습니다.");
        return;
      }
      const screenWidth = window.screen.width;
      window.open(
        path,
        `${this.$route.path}_${this.moment().valueOf()}`,
        `popup=yes,width=${screenWidth},height=800,${option}`,
      );
    };

    app.config.globalProperties.$tabOpen = function (path: string) {
      if (!path) {
        alert("경로가 올바르지 않습니다.");
        return;
      }
      window.open(path, `${this.$route.path}_${this.moment().valueOf()}`);
    };
    app.config.globalProperties.$assetsPath = process.env.VUE_APP_ASSETS_PATH;
    app.config.globalProperties.$ALERT_ACTION = ALERT_ACTION;
    app.config.globalProperties.$PROGRESS_START_ACTION = PROGRESS_START_ACTION;
    app.config.globalProperties.$PROGRESS_END_ACTION = PROGRESS_END_ACTION;

    app.use(axios);
    app.use(vClickOutside);

    app.use(useToast);

    app.use(BodyScrollLock);
    app.use(ElementPlus, { locale: ko });
    app.use(VCalendar, {});
    app.use(VuePdf);

    app.use(filters);

    app.mixin({
      computed: {
        $_: function () {
          app.config.globalProperties.$_ = _;
          return _;
        },
      },
    });
    app.provide("$apiPath", apiPath);
    app.provide("$ALERT_ACTION", ALERT_ACTION);
    app.provide("$routerPath", routerPath);
    app.provide("$paymentRouterPath", paymentRouterPath);
    app.provide("moment", moment);
    app.provide("$toast", toast);
  },
};
