<template>
  <Suspense>
    <router-view />
  </Suspense>
</template>
<script>
export default {
  name: "App",
  components: {},
};
</script>
