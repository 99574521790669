import routerPath from "./routerPath";
import menuId from "@/constants/menuId";
import paymentRouter from "@/router/paymentRouter";

const routes = [
  {
    path: routerPath.LOGIN,
    meta: {
      isOnlyUser: true,
      requiresAuth: false,
    },
    component: () => import("@/pages/user/Login.vue"),
  },
  {
    path: routerPath.LOGIN_ADMIN,
    meta: {
      isOnlyAdmin: true,
      requiresAuth: false,
    },
    component: () => import("@/pages/admin/Login.vue"),
  },
  {
    path: routerPath.CAS_LOGIN_SUCCESS,
    meta: {
      isOnlyAdmin: true,
      requiresAuth: false,
    },
    component: () => import("@/pages/admin/LoginSuccess.vue"),
  },
  {
    path: routerPath.JOIN,
    name: routerPath.JOIN,
    meta: {
      isOnlyUser: true,
      requiresAuth: false,
    },
    component: () => import("@/pages/user/Join.vue"),
  },
  {
    path: routerPath.JOIN_INQUIRY,
    meta: {
      isOnlyUser: true,
      requiresAuth: false,
    },
    component: () => import("@/pages/user/JoinInquiry.vue"),
  },
  {
    path: routerPath.MY_INFO,
    meta: {
      isOnlyUser: true,
      menuId: "myInfo",
    },
    component: () => import("@/pages/user/myinfo/AccountEdit.vue"),
  },
  {
    path: routerPath.HOME,
    meta: {
      menuId: menuId.SYSTEM_NOTICE_READ,
    },
    component: () => import("@/pages/shared/notice/NoticeList.vue"),
  },
  {
    path: routerPath.INFO, // 기본정보
    meta: {
      menuId: menuId.ITEM_READ,
    },
    redirect: routerPath.ITEM,
    component: () => import("@/components/layout/EmptyRouterView.vue"),
    children: [
      {
        path: routerPath.ITEM, // 품목관리
        meta: {
          menuId: menuId.ITEM_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        redirect: routerPath.ITEM_LIST,
        children: [
          {
            path: routerPath.ITEM_LIST,
            meta: {
              menuId: menuId.ITEM_READ,
            },
            component: () => import("@/pages/admin/item/ItemList.vue"),
          },
          {
            path: `${routerPath.ITEM_VIEW}/:id`,
            meta: {
              menuId: menuId.ITEM_READ,
            },
            component: () => import("@/pages/admin/item/ItemView.vue"),
          },
          {
            path: `${routerPath.ITEM_WRITE}`,

            meta: {
              menuId: menuId.ITEM_WRITE,
            },
            component: () => import("@/pages/admin/item/ItemWrite.vue"),
          },
          {
            path: `${routerPath.ITEM_MODIFY}/:id`,

            meta: {
              menuId: menuId.ITEM_WRITE,
              isModifyMode: true,
            },
            component: () => import("@/pages/admin/item/ItemWrite.vue"),
          },
        ],
      },
      {
        path: routerPath.PARTNER, // 파트너관리
        meta: {
          menuId: menuId.PARTNER_READ,
        },
        redirect: routerPath.PARTNER_LIST,
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        children: [
          {
            path: routerPath.PARTNER_LIST,
            meta: {
              menuId: menuId.PARTNER_READ,
            },
            component: () => import("@/pages/admin/partner/PartnerList.vue"),
          },
          {
            path: `${routerPath.PARTNER_VIEW}/:id`,
            meta: {
              menuId: menuId.PARTNER_READ,
            },
            component: () => import("@/pages/admin/partner/PartnerView.vue"),
          },
          // {
          //   path: `${routerPath.PARTNER_WRITE}/:id`,

          //   meta: {
          //     menuId: menuId.PARTNER_WRITE,
          //   },
          //   component: () => import('@/views/Home.vue'),
          // },
        ],
      },
      {
        path: routerPath.PERFORMANCE, // 수행평가도관리
        meta: {
          menuId: menuId.PERFORMANCE_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        redirect: routerPath.PERFORMANCE_LIST,
        children: [
          {
            path: routerPath.PERFORMANCE_LIST,
            meta: {
              menuId: menuId.PERFORMANCE_READ,
            },
            component: () => import("@/pages/admin/performance/PerformanceList.vue"),
          },
          {
            path: `${routerPath.PERFORMANCE_VIEW}/:id`,
            meta: {
              menuId: menuId.PERFORMANCE_READ,
            },
            component: () => import("@/pages/admin/performance/PerformanceView.vue"),
          },
          {
            path: `${routerPath.PERFORMANCE_WRITE}/:id/:inspectId`,
            meta: {
              menuId: menuId.PERFORMANCE_WRITE,
            },
            component: () => import("@/pages/admin/performance/PerformanceWrite.vue"),
          },
          {
            path: `${routerPath.PERFORMANCE_MODIFY}/:id`,
            meta: {
              menuId: menuId.PERFORMANCE_WRITE,
              isModifyMode: true,
            },
            component: () => import("@/pages/admin/performance/PerformanceWrite.vue"),
          },
        ],
      },
    ],
  },
  {
    path: routerPath.PURCHASE_LIST, // 구매관리
    meta: {
      menuId: menuId.PURCHASE_READ,
    },
    component: () => import("@/components/layout/EmptyRouterView.vue"),
    redirect: routerPath.PURCHASE_LIST,
    children: [
      {
        path: routerPath.PURCHASE_LIST, // 구매요청
        meta: {
          menuId: menuId.PURCHASE_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        children: [
          {
            path: routerPath.PURCHASE_LIST,
            meta: {
              menuId: menuId.PURCHASE_READ,
            },
            component: () => import("@/pages/admin/purchase/PurchaseList.vue"),
          },
          {
            path: `${routerPath.PURCHASE_VIEW}/:id`,
            meta: {
              menuId: menuId.PURCHASE_READ,
            },
            component: () => import("@/pages/admin/purchase/PurchaseView.vue"),
          },
          {
            path: routerPath.PURCHASE_WRITE,
            name: routerPath.PURCHASE_WRITE,
            meta: {
              menuId: menuId.PURCHASE_WRITE,
            },
            component: () => import("@/pages/admin/purchase/PurchaseWrite.vue"),
          },
          {
            path: `${routerPath.PURCHASE_MODIFY}/:id`,
            meta: {
              menuId: menuId.PURCHASE_WRITE,
              isModifyMode: true,
            },
            component: () => import("@/pages/admin/purchase/PurchaseWrite.vue"),
          },
        ],
      },
      {
        path: routerPath.ESTIMATE_LIST, // 견적관리
        meta: {
          menuId: menuId.ESTIMATE_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        children: [
          {
            path: routerPath.ESTIMATE_LIST,
            meta: {
              menuId: menuId.ESTIMATE_READ,
            },
            component: () => import("@/pages/shared/estimate/EstimateList"),
          },
          {
            path: `${routerPath.ESTIMATE_VIEW}/:id`,
            meta: {
              menuId: menuId.ESTIMATE_READ,
            },
            component: () => import("@/pages/shared/estimate/EstimateView"),
          },
          {
            path: `${routerPath.ESTIMATE_WRITE}`,
            name: `${routerPath.ESTIMATE_WRITE}`,
            meta: {
              menuId: menuId.ESTIMATE_WRITE,
              isOnlyAdmin: true,
            },
            component: () => import("@/pages/admin/estimate/EstimateWrite"),
          },
          {
            path: `${routerPath.ESTIMATE_WRITE}/:id`,
            meta: {
              menuId: menuId.ESTIMATE_WRITE,
              isOnlyUser: true,
            },
            component: () => import("@/pages/user/estimate/EstimateWrite"),
          },
          {
            path: `${routerPath.ESTIMATE_MODIFY}/:id`,
            meta: {
              menuId: menuId.ESTIMATE_WRITE,

              isModifyMode: true,
            },
            component: () => import("@/pages/admin/estimate/EstimateWrite"),
          },
        ],
      },
      {
        path: routerPath.ANNOUNCE, // 공고관리
        meta: {
          menuId: menuId.ANNOUNCE_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView"),
        redirect: routerPath.ANNOUNCE_LIST,
        children: [
          {
            path: routerPath.ANNOUNCE_LIST,
            meta: {
              menuId: menuId.ANNOUNCE_READ,
            },
            component: () => import("@/pages/shared/announce/AnnounceList"),
          },
          {
            path: `${routerPath.ANNOUNCE_VIEW}/:id`,
            meta: {
              menuId: menuId.ANNOUNCE_READ,
            },
            component: () => import("@/pages/shared/announce/AnnounceView"),
          },
          {
            path: `${routerPath.ANNOUNCE_WRITE}/:id`,
            name: `${routerPath.ANNOUNCE_WRITE}`,
            meta: {
              menuId: menuId.ANNOUNCE_WRITE,
            },
            component: () => import("@/pages/admin/announce/AnnounceWrite"),
          },
          {
            path: `${routerPath.ANNOUNCE_MODIFY}/:id`,
            meta: {
              menuId: menuId.ANNOUNCE_WRITE,
              isModifyMode: true,
            },
            component: () => import("@/pages/admin/announce/AnnounceWrite"),
          },
        ],
      },
      {
        path: routerPath.BID, // 입찰관리
        meta: {
          menuId: menuId.BID_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        redirect: routerPath.BID_LIST,
        children: [
          {
            path: routerPath.BID_LIST,
            meta: {
              menuId: menuId.BID_READ,
            },
            component: () => import("@/pages/shared/bid/BidList.vue"),
          },
          {
            path: `${routerPath.BID_VIEW}/:id`,
            meta: {
              menuId: menuId.BID_READ,
            },
            component: () => import("@/pages/shared/bid/BidView.vue"),
          },
          {
            path: `${routerPath.BID_WRITE}/:id`,
            meta: {
              menuId: menuId.BID_WRITE,
            },
            component: () => import("@/pages/user/bid/BidWrite.vue"),
          },
        ],
      },
    ],
  },
  {
    path: routerPath.DRAFT, // 입찰관리
    meta: {
      menuId: menuId.DRAFT_READ,
    },
    component: () => import("@/components/layout/EmptyRouterView.vue"),
    redirect: routerPath.DRAFT_LIST,
    children: [
      {
        path: routerPath.DRAFT, // 기안관리
        meta: {
          menuId: menuId.DRAFT_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        redirect: routerPath.DRAFT_LIST,
        children: [
          {
            path: routerPath.DRAFT_LIST,
            meta: {
              menuId: menuId.DRAFT_READ,
            },

            component: () => import("@/pages/admin/draft/DraftList"),
          },
          {
            path: `${routerPath.DRAFT_VIEW}/:id`,
            meta: {
              menuId: menuId.DRAFT_READ,
            },
            component: () => import("@/pages/admin/draft/DraftView"),
          },
          {
            path: routerPath.DRAFT_WRITE,
            name: routerPath.DRAFT_WRITE,
            meta: {
              menuId: menuId.DRAFT_WRITE,
            },
            component: () => import("@/pages/admin/draft/DraftWrite"),
          },
          {
            path: `${routerPath.DRAFT_MODIFY}/:id`,
            meta: {
              menuId: menuId.DRAFT_WRITE,
              isModifyMode: true,
            },
            component: () => import("@/pages/admin/draft/DraftWrite"),
          },
        ],
      },
      {
        path: routerPath.INCREASE, // 기안증액관리
        meta: {
          menuId: menuId.INCREASE_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        redirect: routerPath.INCREASE_LIST,
        children: [
          {
            path: routerPath.INCREASE_LIST,
            meta: {
              menuId: menuId.INCREASE_READ,
            },
            component: () => import("@/pages/admin/increase/IncreaseList.vue"),
          },
          {
            path: `${routerPath.INCREASE_VIEW}/:id`,
            meta: {
              menuId: menuId.INCREASE_READ,
            },
            component: () => import("@/pages/admin/increase/IncreaseView.vue"),
          },
          {
            path: `${routerPath.INCREASE_WRITE}`,
            name: `${routerPath.INCREASE_WRITE}`,
            meta: {
              menuId: menuId.INCREASE_READ,
            },
            component: () => import("@/pages/admin/increase/IncreaseWrite.vue"),
          },
          {
            path: `${routerPath.INCREASE_MODIFY}/:id`,
            name: `${routerPath.INCREASE_MODIFY}`,
            meta: {
              menuId: menuId.INCREASE_READ,
              isModifyMode: true,
            },
            component: () => import("@/pages/admin/increase/IncreaseWrite.vue"),
          },
        ],
      },
    ],
  },

  {
    path: routerPath.CONTRACT, // 발주/계약
    meta: {
      menuId: menuId.CONTRACT_READ,
    },
    component: () => import("@/components/layout/EmptyRouterView.vue"),
    redirect: routerPath.CONTRACT_LIST,
    children: [
      {
        path: routerPath.CONTRACT, // 계약
        meta: {
          menuId: menuId.CONTRACT_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        redirect: routerPath.CONTRACT_LIST,
        children: [
          {
            path: routerPath.CONTRACT_LIST,
            meta: {
              menuId: menuId.CONTRACT_READ,
            },
            component: () => import("@/pages/shared/contract/ContractList.vue"),
          },
          {
            path: `${routerPath.CONTRACT_VIEW}/:id`,
            meta: {
              menuId: menuId.CONTRACT_READ,
            },
            component: () => import("@/pages/shared/contract/ContractView.vue"),
          },
          {
            path: `${routerPath.CONTRACT_WRITE}`,
            name: `${routerPath.CONTRACT_WRITE}`,
            meta: {
              menuId: menuId.CONTRACT_WRITE,
            },
            component: () => import("@/pages/admin/contract/ContractInfoWrite.vue"),
          },
          {
            path: `${routerPath.CONTRACT_MODIFY}/:id`,
            name: `${routerPath.CONTRACT_MODIFY}`,
            meta: {
              menuId: menuId.CONTRACT_WRITE,
              isModifyMode: true,
            },
            component: () => import("@/pages/admin/contract/ContractInfoWrite.vue"),
          },
          {
            path: `${routerPath.CONTRACT_RENEWAL}`, //변경계약
            name: `${routerPath.CONTRACT_RENEWAL}`,
            meta: {
              menuId: menuId.CONTRACT_WRITE,
              isModifyMode: true,
              isRenewalMode: true,
            },
            component: () => import("@/pages/admin/contract/ContractInfoWrite.vue"),
          },
        ],
      },
      {
        path: routerPath.ORDER, // 발주
        meta: {
          menuId: menuId.ORDER_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        redirect: routerPath.ORDER_LIST,
        children: [
          {
            path: routerPath.ORDER_LIST,
            meta: {
              menuId: menuId.ORDER_READ,
            },
            component: () => import("@/pages/shared/order/OrderList.vue"),
          },
          {
            path: `${routerPath.ORDER_VIEW}/:id`,
            meta: {
              menuId: menuId.ORDER_READ,
            },
            component: () => import("@/pages/shared/order/OrderView.vue"),
          },
          {
            path: `${routerPath.ORDER_WRITE}/:id`,
            name: `${routerPath.ORDER_WRITE}`,
            meta: {
              menuId: menuId.ORDER_WRITE,
            },
            component: () => import("@/pages/admin/order/OrderWrite.vue"),
          },
          {
            path: `${routerPath.ORDER_MODIFY}/:id`,
            meta: {
              menuId: menuId.ORDER_WRITE,
              isModifyMode: true,
            },
            component: () => import("@/pages/admin/order/OrderWrite.vue"),
          },
          {
            path: `${routerPath.ORDER_RENEWAL}`, //변경발주
            name: `${routerPath.ORDER_RENEWAL}`,
            meta: {
              menuId: menuId.ORDER_WRITE,
              isModifyMode: true,
              isRenewalMode: true,
            },
            component: () => import("@/pages/admin/order/OrderWrite.vue"),
          },
        ],
      },
    ],
  },
  {
    path: routerPath.INSPECT, // 마감/정산
    meta: {
      menuId: menuId.INSPECT_READ,
    },
    component: () => import("@/components/layout/EmptyRouterView.vue"),
    redirect: routerPath.INSPECT_LIST,
    children: [
      {
        path: routerPath.INSPECT, //검수
        meta: {
          menuId: menuId.INSPECT_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        redirect: routerPath.INSPECT_LIST,
        children: [
          {
            path: routerPath.INSPECT_LIST,
            meta: {
              menuId: menuId.INSPECT_READ,
            },
            component: () => import("@/pages/shared/inspect/InspectList.vue"),
          },
          {
            path: `${routerPath.INSPECT_VIEW}/:refPk`,
            meta: {
              menuId: menuId.INSPECT_READ,
            },
            component: () => import("@/pages/shared/inspect/InspectView.vue"),
          },
          // {
          //   path: `${routerPath.INSPECT_WRITE}/:id`,
          //   meta: {
          //     menuId: menuId.INSPECT_WRITE,
          //   },
          //   component: () => import('@/pages/admin/inspect/InspectWrite.vue'),
          // },
          {
            path: `${routerPath.INSPECT_WRITE}/:refPk`,
            meta: {
              menuId: menuId.INSPECT_WRITE,
              isRefPk: true,
            },
            component: () => import("@/pages/shared/inspect/InspectWrite.vue"),
          },
        ],
      },
      {
        path: routerPath.SETTLEMENT, //정산서
        meta: {
          menuId: menuId.SETTLEMENT_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        redirect: routerPath.SETTLEMENT_LIST,
        children: [
          {
            path: routerPath.SETTLEMENT_LIST,
            meta: {
              menuId: menuId.SETTLEMENT_READ,
            },

            component: () => import("@/pages/admin/settlement/SettlementList.vue"),
          },
          {
            path: `${routerPath.SETTLEMENT_VIEW}/:id`,
            meta: {
              menuId: menuId.SETTLEMENT_READ,
            },
            component: () => import("@/pages/admin/settlement/SettlementView.vue"),
          },
          {
            path: `${routerPath.SETTLEMENT_WRITE}`,
            meta: {
              menuId: menuId.SETTLEMENT_WRITE,
            },
            component: () => import("@/pages/admin/settlement/SettlementWrite.vue"),
          },
          {
            path: `${routerPath.SETTLEMENT_MODIFY}/:id`,
            meta: {
              menuId: menuId.SETTLEMENT_WRITE,
              isModifyMode: true,
            },
            component: () => import("@/pages/admin/settlement/SettlementWrite.vue"),
          },
        ],
      },

      {
        path: routerPath.TRANSACTIONREPORT,
        meta: {
          menuId: menuId.TRANSACTIONREPORT_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        redirect: routerPath.TRANSACTIONREPORT_LIST,
        children: [
          {
            path: routerPath.TRANSACTIONREPORT_LIST,
            meta: {
              menuId: menuId.TRANSACTIONREPORT_READ,
            },
            component: () => import("@/pages/shared/transactionReport/TransactionReportList.vue"),
          },
          {
            path: `${routerPath.TRANSACTIONREPORT_VIEW}/:id`,
            meta: {
              menuId: menuId.TRANSACTIONREPORT_READ,
            },
            component: () => import("@/pages/shared/transactionReport/TransactionReportView.vue"),
          },
          // {
          //   path: `${ligRouterPath.TRANSACTIONREPORT_WRITE}/:id`,
          //   meta: {
          //     menuId: menuId.TRANSACTIONREPORT_WRITE,
          //   },
          //   component: () => import('@/pages/Dummy.vue'),
          // },
        ],
      },
    ],
  },
  ...paymentRouter,

  // {
  //   path: routerPath.STATISTICS_PURCHASE, // 통계
  //   meta: {
  //     menuId: menuId.STATISTICS,
  //   },
  //   component: () => import('@/components/layout/EmptyRouterView.vue'),
  //   children: [
  //     {
  //       path: routerPath.STATISTICS_PURCHASE, // 구매요청 진행현황
  //       meta: {
  //         menuId: menuId.STATISTICS_PURCHASE_READ,
  //       },
  //       component: () => import('@/pages/admin/statistics/StatisticsPurchaseList.vue'),
  //     },
  //     {
  //       path: routerPath.STATISTICS_ORDER, // 발주현황
  //       meta: {
  //         menuId: menuId.STATISTICS_ORDER_READ,
  //       },
  //       component: () => import('@/pages/admin/statistics/StatisticsOrderList.vue'),
  //     },
  //   ],
  // },

  {
    path: "/system", //  routerPath.SYSTEM, // 시스템관리
    meta: {
      menuId: menuId.SYSTEM,
    },
    component: () => import("@/components/layout/EmptyRouterView.vue"),

    children: [
      {
        path: routerPath.SYSTEM_CODE, // 코드관리
        meta: {
          menuId: menuId.SYSTEM_CODE_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        redirect: routerPath.SYSTEM_CODE_LIST,
        children: [
          {
            path: routerPath.SYSTEM_CODE_LIST,
            meta: {
              menuId: menuId.SYSTEM_CODE_READ,
            },
            component: () => import("@/pages/Dummy.vue"),
          },
          {
            path: routerPath.SYSTEM_CODE_WRITE,
            meta: {
              menuId: menuId.SYSTEM_CODE_WRITE,
            },
            component: () => import("@/pages/Dummy.vue"),
          },
        ],
      },
      {
        path: routerPath.SYSTEM_HANDLED, // 취급품목
        meta: {
          menuId: menuId.SYSTEM_HANDLED,
        },
        component: () => import("@/pages/admin/system/handle/Handle.vue"),
      },
      {
        path: routerPath.SYSTEM_CATEGORY, // 카테고리
        meta: {
          menuId: menuId.SYSTEM_CATEGORY,
        },

        component: () => import("@/pages/admin/system/category/CategoryManagement.vue"),
      },
      {
        path: routerPath.SYSTEM_TEMPLATE, // 템플릿관리
        meta: {
          menuId: menuId.SYSTEM_TEMPLATE_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        redirect: routerPath.SYSTEM_TEMPLATE_LIST,
        children: [
          {
            path: routerPath.SYSTEM_TEMPLATE_LIST,
            meta: {
              menuId: menuId.SYSTEM_TEMPLATE_READ,
            },
            component: () => import("@/pages/admin/system/template/TemplateList.vue"),
          },
          {
            path: routerPath.SYSTEM_TEMPLATE_WRITE,
            meta: {
              menuId: menuId.SYSTEM_TEMPLATE_WRITE,
            },
            component: () => import("@/pages/admin/system/template/TemplateWrite.vue"),
          },
          {
            path: `${routerPath.SYSTEM_TEMPLATE_MODIFY}`,
            name: `${routerPath.SYSTEM_TEMPLATE_MODIFY}`,
            meta: {
              menuId: menuId.SYSTEM_TEMPLATE_WRITE,
              isModifyMode: true,
            },
            component: () => import("@/pages/admin/system/template/TemplateModify.vue"),
          },
        ],
      },
      {
        path: routerPath.SYSTEM_PERFORMANCE, // 업체(평가)관리
        meta: {
          menuId: menuId.SYSTEM_PERFORMANCE_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        children: [
          {
            path: routerPath.SYSTEM_PERFORMANCE_SETTING,
            meta: {
              menuId: menuId.SYSTEM_PERFORMANCE_READ,
            },
            component: () =>
              import("@/pages/admin/system/performance/SystemPerformanceSettingList.vue"),
          },
          {
            path: routerPath.SYSTEM_PERFORMANCE_WRITE,
            meta: {
              menuId: menuId.SYSTEM_PERFORMANCE_WRITE,
            },
            component: () => import("@/pages/admin/system/performance/SystemPerformanceWrite.vue"),
          },
          {
            path: `${routerPath.SYSTEM_PERFORMANCE_MODIFY}/:id`,
            meta: {
              menuId: menuId.SYSTEM_PERFORMANCE_WRITE,
              isModifyMode: true,
            },
            component: () => import("@/pages/admin/system/performance/SystemPerformanceWrite.vue"),
          },
          {
            path: `${routerPath.SYSTEM_PERFORMANCE_SETTING}/:id`,
            meta: {
              menuId: menuId.SYSTEM_PERFORMANCE_READ,
            },
            component: () => import("@/pages/admin/system/performance/SystemPerformanceView.vue"),
          },
          {
            path: routerPath.SYSTEM_PERFORMANCE_PRESENT,
            meta: {
              menuId: menuId.SYSTEM_PERFORMANCE_READ,
            },
            component: () =>
              import("@/pages/admin/system/performance/SystemPerformancePresentList.vue"),
          },
          {
            path: routerPath.SYSTEM_PERFORMANCE_RESULT,
            meta: {
              menuId: menuId.SYSTEM_PERFORMANCE_READ,
            },
            component: () =>
              import("@/pages/admin/system/performance/SystemPerformanceResultList.vue"),
          },
        ],
      },
      {
        path: routerPath.SYSTEM_DIRECTOR, // 담당자관리
        meta: {
          menuId: menuId.SYSTEM_DIRECTOR_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        redirect: routerPath.SYSTEM_DIRECTOR_LIST,
        children: [
          {
            path: routerPath.SYSTEM_DIRECTOR_LIST,
            meta: {
              menuId: menuId.SYSTEM_DIRECTOR_READ,
            },

            component: () => import("@/pages/admin/system/director/DirectorList.vue"),
          },
          {
            path: routerPath.SYSTEM_DIRECTOR_WRITE,
            meta: {
              menuId: menuId.SYSTEM_DIRECTOR_WRITE,
            },
            component: () => import("@/pages/admin/system/director/DirectorWrite.vue"),
          },
          {
            path: `${routerPath.SYSTEM_DIRECTOR_MODIFY}/:id`,
            meta: {
              menuId: menuId.SYSTEM_DIRECTOR_WRITE,
              isModifyMode: true,
            },
            component: () => import("@/pages/admin/system/director/DirectorModify.vue"),
          },
        ],
      },
      {
        path: routerPath.SYSTEM_MENU, // 메뉴관리
        meta: {
          menuId: menuId.SYSTEM_MENU_READ,
        },
        component: () => import("@/pages/admin/system/menu/Menu.vue"),
      },
      {
        path: routerPath.SYSTEM_APPROVALLINE, // 결재선관리
        meta: {
          menuId: menuId.SYSTEM_APPROVALLINE_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        redirect: routerPath.SYSTEM_APPROVALLINE_LIST,
        children: [
          {
            path: routerPath.SYSTEM_APPROVALLINE_LIST,
            meta: {
              menuId: menuId.SYSTEM_APPROVALLINE_READ,
            },
            component: () => import("@/pages/admin/system/approvalLine/ApprovalLineList.vue"),
          },
          {
            path: `${routerPath.SYSTEM_APPROVALLINE_VIEW}/:id`,
            meta: {
              menuId: menuId.SYSTEM_APPROVALLINE_READ,
            },
            component: () => import("@/pages/admin/system/approvalLine/ApprovalLineView.vue"),
          },
          {
            path: `${routerPath.SYSTEM_APPROVALLINE_WRITE}`,
            meta: {
              menuId: menuId.SYSTEM_APPROVALLINE_WRITE,
            },
            component: () => import("@/pages/admin/system/approvalLine/ApprovalLineWrite.vue"),
          },
          {
            path: `${routerPath.SYSTEM_APPROVALLINE_MODIFY}/:id`,
            meta: {
              menuId: menuId.SYSTEM_APPROVALLINE_WRITE,
              isModifyMode: true,
            },
            component: () => import("@/pages/admin/system/approvalLine/ApprovalLineWrite.vue"),
          },
        ],
      },
      {
        path: routerPath.SYSTEM_NOTICE, // 공지사항
        meta: {
          menuId: menuId.SYSTEM_NOTICE_READ,
        },
        component: () => import("@/components/layout/EmptyRouterView.vue"),
        redirect: routerPath.SYSTEM_NOTICE_LIST,
        children: [
          {
            path: routerPath.SYSTEM_NOTICE_LIST,
            meta: {
              menuId: menuId.SYSTEM_NOTICE_READ,
            },
            component: () => import("@/pages/shared/notice/NoticeList.vue"),
          },
          {
            path: `${routerPath.SYSTEM_NOTICE_VIEW}/:id`,
            meta: {
              menuId: menuId.SYSTEM_NOTICE_READ,
            },
            component: () => import("@/pages/shared/notice/NoticeView.vue"),
          },
          {
            path: `${routerPath.SYSTEM_NOTICE_WRITE}`,
            meta: {
              menuId: menuId.SYSTEM_NOTICE_WRITE,
            },
            component: () => import("@/pages/admin/system/notice/NoticeWrite.vue"),
          },
          {
            path: `${routerPath.SYSTEM_NOTICE_MODIFY}/:id`,
            meta: {
              menuId: menuId.SYSTEM_NOTICE_WRITE,
              isModifyMode: true,
            },
            component: () => import("@/pages/admin/system/notice/NoticeWrite.vue"),
          },
        ],
      },
    ],
  },

  {
    path: `/templatePreview`, //
    name: `/templatePreview`,
    meta: {
      menuId: menuId.CONTRACT_WRITE,
    },
    component: () => import("@/components/shared/template/TemplatePreviewWindow.vue"),
  },

  //  {
  //    path: routerPath.HOME,
  //    meta: {
  //      menuId: menuId.menuId,
  //    },
  //    component: () => import('@/views/Home.vue'),
  //  },

  { path: "/:catchAll(.*)", redirect: routerPath.HOME },
];

export default routes;
