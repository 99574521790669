import { ESTIMATE_LIST_ACTION, GET_ESTIMATE_STATUS_ACTION } from "./action";
import { ESTIMATE_STATUS_MUTATION } from "./mutation";

import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";

import LocalStorageManager from "@/LocalStorageManager";

import { getCompanyType } from "@/utils/spUtils";

export default {
  state: {
    // 상태는 구매사용, 공급사용 따로 있어야 할 것 같다. 매번 새로 안가져오려면.
    statusList: [],
  },
  actions: {
    async [GET_ESTIMATE_STATUS_ACTION](context) {
      const statusList = context.state.statusList;
      if (statusList.length > 0) return; // 한번만 호출.

      const path = apiPath.ESTIMATE_STATUS;

      const result = await ApiService.shared.getData(path);
      console.log("result :>> ", result);

      const { code, data, text } = result;
      if (code !== "200") {
        return;
      }

      this.commit({
        type: ESTIMATE_STATUS_MUTATION,
        items: data,
      });
    },
    async [ESTIMATE_LIST_ACTION](context) {
      await context.dispatch(GET_ESTIMATE_STATUS_ACTION);
    },
  },
  mutations: {
    [ESTIMATE_STATUS_MUTATION](state, payload) {
      const { items } = payload;

      //       0: {codeId: "0", codeName: "전체"}
      // 1: {codeId: "T", codeName: "임시저장"}
      // 2: {codeId: "R", codeName: "견적요청"}
      // 3: {codeId: "S", codeName: "견적회신"}

      state.statusList = items;
    },
  },
};

export { ESTIMATE_LIST_ACTION };
