export function ellipsis(data, cutoff, strAdd) {
  let str = data.toString(); // cast numbers

  if (!strAdd) {
    strAdd = "&#8230;";
  }

  str = str.length < cutoff ? str : str.substr(0, cutoff - 1) + strAdd;

  return str;
}

export function decodeHTMLEntities(docu, text) {
  let textArea = docu.createElement("textarea");
  textArea.innerHTML = text;
  const { value } = textArea;

  textArea = null;
  return value;
}

export function addCommaForMoney(value) {
  if (value === 0) return "0";

  if (!value) return value;

  const setMoney = String(value);

  let arrStrMoney = [];

  arrStrMoney = setMoney.split(".");

  const strMoney = arrStrMoney[0];

  if (strMoney.indexOf(",") > -1) return strMoney;

  let ret = "";
  let nFromEnd;
  let char;

  for (let i = 0; i < strMoney.length; ++i) {
    char = strMoney[i];

    nFromEnd = strMoney.length - 1 - i;

    ret += char;
    if (nFromEnd !== 0 && nFromEnd % 3 === 0 && char !== "-") {
      ret += ",";
    }
  }
  if (arrStrMoney[1]) {
    ret = `${ret}.${arrStrMoney[1]}`;
  }
  return ret;
}

export function getMoneyToKorean(money) {
  if (!money) return "";

  const strMoneyWithoutComma = String(money).split(",").join("");

  const moneyStringArray = strMoneyWithoutComma.split("");
  const unitArray = ["", "십", "백", "천"]; // 한단위
  const unitArray2 = ["", "만", "억", "조"]; // 한묶음단위
  const numberKoreanArray = ["영", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"]; // index 순서에 맞게 한글 배열 만들어둠
  const koreanResult = []; // 한글값으로 변환된 배열이 담길 곳
  let result = ""; // 최종결과
  // 숫자 -> 한글값으로 변환
  moneyStringArray.forEach((e, i) => {
    const numItem = Number(e);
    const koreanItem = numberKoreanArray[numItem];
    koreanResult.push(koreanItem);
  });
  // 한글값으로 변환된 배열 뒤에서부터(작은단위숫자부터) 읽기
  for (let n = 0; n < koreanResult.length; n++) {
    const uppendNum = koreanResult.length - n - 1;
    const unitIndex = uppendNum % 4;
    const unitIndex2 = Math.floor(uppendNum / 4);
    let unit = "";
    // 한묶음(만,억,조) 단위내의 값의 유무체크
    if (unitIndex == 0 && koreanResult.slice(n - 3, n + 1).toString() != "영,영,영,영") {
      // 한묶음단위 담기
      unit = unitArray2[unitIndex2];
    } else if (koreanResult[n] != "영") {
      // 한단위 담기
      unit = unitArray[unitIndex];
    }
    // 한단위(십,백,천)의 값이 없는 경우 체크
    // if(koreanResult[n] == '영' || (koreanResult[n] == '일' && n != 0)){
    // ㄴ> 제일 앞에만 '일' 덧붙이고 중간에는 '일'을 덧붙이지 않는 경우에 사용하면 됨
    // ⌐> 현재는 제일 앞, 중간 모두 '일'을 덧붙임
    if (koreanResult[n] == "영") {
      // 단위 담기
      result += unit;
    } else {
      // 숫자값 + 단위 담기
      result += koreanResult[n] + unit;
    }
  }
  return result;
}

export function getMoneyToNumber(money) {
  if (!money) return 0;

  const strMoney = String(money).trim();
  let arrStrMoney = [];

  arrStrMoney = strMoney.split(".");

  const strMoneyWithoutComma = arrStrMoney[0].split(",").join("");

  if (!strMoneyWithoutComma || isNaN(strMoneyWithoutComma)) return 0;

  let ret = Number.parseInt(strMoneyWithoutComma, 10);
  if (arrStrMoney[1]) {
    ret = `${ret}.${arrStrMoney[1]}`;
  }

  return ret;
}

export function make00(nCount) {
  let ret;

  if (nCount < 10) {
    ret = `0${nCount}`;
  } else {
    ret = String(nCount);
  }
  return ret;
}

export function getIsKorean(str) {
  const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

  const ret = reg.exec(str) !== null;

  return ret;
}

export function getIsValidHtml(str) {
  str = str.split("<br>").join("");
  str = str.split("<p>").join("");
  str = str.split("</p>").join("");

  return str;
}

export function removeComma(value) {
  if (isNaN(value)) {
    value = String(value);
  }

  return value.replaceAll(",", "");
}

export function escapeAmp(str = "") {
  return str.includes("&amp;") ? str.replace(/&amp;/g, "&") : str;
}

export function changeOnlyEnglish(value) {
  let str = "";
  if (value !== null) {
    str = value.replace(/[^a-zA-Z]/g, "");
  }
  return str;
}

export function changeOnlyEnglishUppercase(value) {
  let str = "";
  if (value !== null) {
    str = value.replace(/[^A-Z]/g, "");
  }
  return str;
}

export function changeOnlyNumberAndEnglishUppercase(value) {
  let str = "";
  if (value !== null) {
    str = value.replace(/[^0-9A-Z]/g, "");
  }

  return str;
}

export function changeOnlyNumberAndEnglish(value) {
  let str = "";
  if (value !== null) {
    str = value.replace(/[^0-9a-zA-Z|-]/g, "");
  }

  return str;
}

export function changeOnlyNumber(value) {
  let str = "";
  if (value) {
    str = value.replace(/[a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, "");
  }
  return str;
}

export function changeOnlyWord(value) {
  let str = "";
  if (value !== null) {
    str = value.replace(/[!,@,#,$,%,^,*,<,>,\s]/g, "");
  }
  return str;
}
