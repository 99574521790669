import { createApp } from "vue";

import ToastPopup from "@/components/layout/popup/ToastPopup.vue";

let FLAG = true;

function toast(contents, duration = 2000) {
  if (FLAG === false) {
    return;
  }
  const toast = createApp(ToastPopup, {
    contents,
    isToast: true,
    isContents: true,
  });
  FLAG = false; // 무한으로 출력 //true 일경우 하나만 출력
  toast.mount(document.querySelector("#toastWrap"));

  setTimeout(() => {
    FLAG = true;

    toast.unmount();
  }, duration);
}
export { toast };
export default {
  install(app) {
    app.config.globalProperties.$toast = toast;
  },
};
