const menuId = {
  //기본정보
  //  INFO: 'info',
  //품목
  ITEM_READ: "item_read",
  ITEM_WRITE: "item_write",
  //파트너
  PARTNER_READ: "partner_read",
  PARTNER_WRITE: "partner_write",
  //수행도평가
  PERFORMANCE_READ: "performance_read",
  PERFORMANCE_WRITE: "performance_write",

  //구매관리
  //구매요청
  PURCHASE_READ: "purchase_read",
  PURCHASE_WRITE: "purchase_write",
  //견적관리
  ESTIMATE_READ: "estimate_read",
  ESTIMATE_WRITE: "estimate_write",
  //공고관리
  ANNOUNCE_READ: "announce_read",
  ANNOUNCE_WRITE: "announce_write",
  //입찰관리
  BID_READ: "bid_read",
  BID_WRITE: "bid_write",
  //기안관리(사용안함)
  DRAFT_READ: "draft_read",
  DRAFT_WRITE: "draft_write",

  //기안관리
  EXPENDITURE_READ: "expenditure_read",
  EXPENDITURE_WRITE: "expenditure_write",

  //증액관리
  INCREASE_READ: "increase_read",
  INCREASE_WRITE: "increase_write",

  //발주/계약
  //계약
  CONTRACT_READ: "contract_read",
  CONTRACT_WRITE: "contract_write",
  //발주
  ORDER_READ: "order_read",
  ORDER_WRITE: "order_write",

  //마감/정산
  //검수
  INSPECT_READ: "inspect_read",
  INSPECT_WRITE: "inspect_write",
  //거래명세서

  TRANSACTIONREPORT_READ: "transactionreport_read",
  TRANSACTIONREPORT_WRITE: "transactionreport_write",
  //정산
  SETTLEMENT_READ: "settlement_read",
  SETTLEMENT_WRITE: "settlement_write",

  //전자결재
  PAYMENT: "payment",
  PAYMENT_SYSTEM: "payment_system",
  // PAYMENT_WRITE: 'payment_write',
  // PAYMENT_EDIT: 'payment_edit',
  // PAYMENT_MY: 'payment_my',
  // PAYMENT_APPROVAL: 'payment_approval',
  // PAYMENT_CC: 'payment_cc',
  // PAYMENT_SETTING: 'payment_setting',
  // PAYMENT_SETTING_LIST: 'payment_setting_list',
  // PAYMENT_SETTING_WRITE: 'payment_setting_write',
  // PAYMENT_GUIDE: 'payment_guide',
  // PAYMENT_GUIDE_LIST: 'payment_guide_list',
  // PAYMENT_GUIDE_WRITE: 'payment_guide_write',

  //통계
  STATISTICS: "statistics",
  STATISTICS_ORDERLIST: "statistics_orderlist",
  STATISTICS_ORDERCASE: "statistics_ordercase",

  //시스템관리://시스템관리
  SYSTEM: "system",
  //코드관리
  SYSTEM_CODE_READ: "system_code_read",
  SYSTEM_CODE_WRITE: "system_code_write",
  //취급품목
  SYSTEM_HANDLED: "system_handled",
  //카테고리관리
  SYSTEM_CATEGORY: "system_category",
  //템플릿관리
  SYSTEM_TEMPLATE_READ: "system_template_read",
  SYSTEM_TEMPLATE_WRITE: "system_template_write",
  //업체평가관리
  SYSTEM_PERFORMANCE_READ: "system_performance_read",
  SYSTEM_PERFORMANCE_WRITE: "system_performance_write", //업체(평가) 생성
  //담당자관리
  SYSTEM_DIRECTOR_READ: "system_director_read",
  SYSTEM_DIRECTOR_WRITE: "system_director_write",
  //메뉴관리
  SYSTEM_MENU_READ: "system_menu_read",
  SYSTEM_MENU_WRITE: "system_menu_write",
  //결재선관리
  SYSTEM_APPROVALLINE_READ: "system_approvalLine_read",
  SYSTEM_APPROVALLINE_WRITE: "system_approvalLine_write",
  //공지사항
  SYSTEM_NOTICE_READ: "system_notice_read",
  SYSTEM_NOTICE_WRITE: "system_notice_write",

  //이하
  /*
  NOTICE: 'notice', // 공지사항
  ANNOUNCE: 'announce', // 공고
  BID: 'bid', // 입찰
  ORDER: 'order', // 발주
  TEMPLATE: 'template', //템플릿

  RECEIVE: 'receive', // 거래

  PARTNERADMIN: 'partnerAdmin', // 파트너 관리
  PARTNER: 'partner', // 파트너 관리
  PERFORMANCE: 'performance', // 수행도 평가 관리

  SERVICE: 'service', // 서비스 관리

  HANDLE: 'handle', // 취급품목 관리
  CATEGORY: 'category', // 카테고리 관리
  MANAGER: 'manager', // 담당자 관리
  NOTIFY: 'notify', // 알림 내역 관리

  CONTRACT_INFO: 'contractInfo', // 계약 관리

  CONTRACT_AND_ORDER: 'contractAndOrder', // 계약/발주 관리

  PURCHASE_REQUEST_GROUP: 'purchaseRequestGroup', // 구매요청 1뎁스 메뉴
  PURCHASE_REQUEST: 'purchaseRequest', // 구매신청
  PURCHASE_REQUEST_DRAFT: 'purchaseRequestDraft', // 구매기안

  SETTLEMENT: 'SETTLEMENT', // 정산서 관리 1뎁스 메뉴
  EXPENSE_SETTLEMENT: 'expenseSETTLEMENT', // 지출 정산서
  INFRA_SETTLEMENT: 'infraSETTLEMENT', // 인프라 정산서
  */
};

export default menuId;
