export const SETTLEMENT_STATUS = "STATUS";
export const BANKCODE = "BANKCODE";
export const BILLTYPE = "BILLTYPE";
export const CURRENCY = "CURRENCY";
export const EVIDENCETYPE = "EVIDENCETYPE";
export const DEDUCTIONTYPE = "DEDUCTIONTYPE";
export const TAXTYPE = "TAXTYPE";
export const MODTAXBILLCODE = "MODTAXBILLCODE";
export const TAXBILL = "TAXBILL";
export const TAXBILLTYPES = "TAXBILLTYPES";
export const TAXCODE = "TAXCODE";
export const PAYMENTMETHOD = "PAYMENTMETHOD";

/* 공통 */
export const SETTLMENT_COMM = "SETTLMENT_COMM";
