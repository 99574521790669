import _ from "lodash";
import routerPath from "@/router/routerPath";
import menuId from "@/constants/menuId";
import LocalStorageManager from "@/LocalStorageManager";

const getMenuData = () => {
  const tokenData = LocalStorageManager.shared.getTokenData();

  const isAdmin =
    tokenData?.aud &&
    (tokenData?.aud.indexOf("ROLE_SUPER") > -1 || tokenData?.aud.indexOf("ROLE_ADMIN") > -1);

  return [
    {
      title: "기본정보",
      link: routerPath.ITEM_LIST,
      arrSub: [
        {
          title: "품목관리",
          link: routerPath.ITEM_LIST,
          roleInfos: [
            {
              menuId: menuId.ITEM_READ,
              roleTitle: "조회",
            },
            {
              menuId: menuId.ITEM_WRITE,
              roleTitle: "등록",
            },
          ],
        },
        {
          title: "협력사 관리",
          link: routerPath.PARTNER_LIST,
          roleInfos: [
            {
              menuId: menuId.PARTNER_READ,
              roleTitle: "조회",
            },
            {
              menuId: menuId.PARTNER_WRITE,
              roleTitle: "등록",
            },
          ],
        },
        // {
        //   title: '수행도평가 관리',
        //   link: routerPath.PERFORMANCE_LIST,
        //   roleInfos: [
        //     {
        //       menuId: menuId.PERFORMANCE_READ,
        //       roleTitle: '조회',
        //     },
        //     {
        //       menuId: menuId.PERFORMANCE_WRITE,
        //       roleTitle: '등록',
        //     },
        //   ],
        // },
      ],
    },
    {
      title: "구매관리",
      link: routerPath.PURCHASE_LIST,
      arrSub: [
        {
          title: "견적관리",
          link: routerPath.ESTIMATE_LIST,
          roleInfos: [
            {
              menuId: menuId.ESTIMATE_READ,
              roleTitle: "조회",
            },
            {
              menuId: menuId.ESTIMATE_WRITE,
              roleTitle: "등록",
            },
          ],
        },
        // {
        //   title: "기안관리",
        //   link: routerPath.DRAFT_LIST,
        //   roleInfos: [
        //     {
        //       menuId: menuId.DRAFT_READ,
        //       roleTitle: "조회",
        //     },
        //     {
        //       menuId: menuId.DRAFT_WRITE,
        //       roleTitle: "등록",
        //     },
        //   ],
        // },
        {
          title: "구매요청",
          link: routerPath.PURCHASE_LIST,
          roleInfos: [
            {
              menuId: menuId.PURCHASE_READ,
              roleTitle: "조회",
            },
            {
              menuId: menuId.PURCHASE_WRITE,
              roleTitle: "등록",
            },
          ],
        },

        {
          title: "공고관리",
          link: routerPath.ANNOUNCE_LIST,
          roleInfos: [
            {
              menuId: menuId.ANNOUNCE_READ,
              roleTitle: "조회",
            },
            {
              menuId: menuId.ANNOUNCE_WRITE,
              roleTitle: "등록",
            },
          ],
        },
        {
          title: "입찰관리",
          link: routerPath.BID_LIST,
          roleInfos: [
            {
              menuId: menuId.BID_READ,
              roleTitle: "조회",
            },
            // {
            //   menuId: menuId.BID_WRITE,
            //   roleTitle: '등록'
            // },
          ],
        },
      ],
    },
    {
      title: "기안관리",
      link: routerPath.DRAFT_LIST,
      arrSub: [
        {
          title: "구매기안관리",
          link: routerPath.DRAFT_LIST,
          roleInfos: [
            {
              menuId: menuId.DRAFT_READ,
              roleTitle: "조회",
            },
            {
              menuId: menuId.DRAFT_WRITE,
              roleTitle: "등록",
            },
          ],
        },
        {
          title: "기안증액관리",
          link: routerPath.INCREASE_LIST,
          roleInfos: [
            {
              menuId: menuId.INCREASE_READ,
              roleTitle: "조회",
            },
            {
              menuId: menuId.INCREASE_WRITE,
              roleTitle: "등록",
            },
          ],
        },
        // {
        //   title: "기안관리",
        //   link: routerPath.EXPENDITURE_LIST,
        //   roleInfos: [
        //     {
        //       menuId: menuId.EXPENDITURE_READ,
        //       roleTitle: "조회",
        //     },
        //     {
        //       menuId: menuId.EXPENDITURE_WRITE,
        //       roleTitle: "등록",
        //     },
        //   ],
        // },
      ],
    },
    {
      title: "계약/발주",
      link: routerPath.CONTRACT_LIST,
      arrSub: [
        {
          title: "계약관리",
          link: routerPath.CONTRACT_LIST,
          roleInfos: [
            {
              menuId: menuId.CONTRACT_READ,
              roleTitle: "조회",
            },
            {
              menuId: menuId.CONTRACT_WRITE,
              roleTitle: "등록",
            },
          ],
        },
        {
          title: "발주관리",
          link: routerPath.ORDER_LIST,
          roleInfos: [
            {
              menuId: menuId.ORDER_READ,
              roleTitle: "조회",
            },
            {
              menuId: menuId.ORDER_WRITE,
              roleTitle: "등록",
            },
          ],
        },
      ],
    },
    {
      title: "마감/정산",
      link: routerPath.INSPECT_LIST,
      arrSub: [
        {
          title: "검수관리",
          link: routerPath.INSPECT_LIST,
          roleInfos: [
            {
              menuId: menuId.INSPECT_READ,
              roleTitle: "조회",
            },
            {
              menuId: menuId.INSPECT_WRITE,
              roleTitle: "등록",
            },
          ],
        },
        {
          title: "정산서관리",
          link: routerPath.SETTLEMENT_LIST,
          roleInfos: [
            {
              menuId: menuId.SETTLEMENT_READ,
              roleTitle: "조회",
            },
            {
              menuId: menuId.SETTLEMENT_WRITE,
              roleTitle: "등록",
            },
          ],
        },

        // {
        //   title: "세금계산서관리",
        //   link: routerPath.TRANSACTIONREPORT_LIST,
        //   roleInfos: [
        //     {
        //       menuId: menuId.TRANSACTIONREPORT_READ,
        //       roleTitle: "조회",
        //     },
        //     {
        //       menuId: menuId.TRANSACTIONREPORT_WRITE,
        //       roleTitle: "등록",
        //     },
        //   ],
        // },

        // {
        //   title: '정산관리',
        //   link: routerPath.SETTLEMENT_LIST,
        //   roleInfos: [
        //     {
        //       menuId: menuId.SETTLEMENT_READ,
        //       roleTitle: '조회',
        //     },
        //     {
        //       menuId: menuId.SETTLEMENT_WRITE,
        //       roleTitle: '등록',
        //     },
        //   ],
        // },
      ],
    },
    {
      ...(isAdmin && {
        title: "전자결재",
        link: routerPath.PAYMENT,
        isNotShowSub: true,
        arrSub: [
          {
            title: "전자결재",
            link: routerPath.PAYMENT,
            roleInfos: [
              {
                menuId: menuId.PAYMENT,
                roleTitle: "조회/등록",
              },
              {
                menuId: menuId.PAYMENT_SYSTEM,
                roleTitle: "관리",
              },
            ],
          },
        ],
      }),
    },
    // {
    //   title: '통계',
    //   link: routerPath.STATISTICS_PURCHASE,
    //   arrSub: [
    //     {
    //       title: '구매요청 진행현황',
    //       link: routerPath.STATISTICS_PURCHASE,
    //       roleInfos: [
    //         {
    //           menuId: menuId.STATISTICS_PURCHASE_READ,
    //           roleTitle: '조회',
    //         },
    //       ],
    //     },
    //     {
    //       title: '발주현황',
    //       link: routerPath.STATISTICS_ORDER,
    //       roleInfos: [
    //         {
    //           menuId: menuId.STATISTICS_ORDER_READ,
    //           roleTitle: '조회',
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      title: "시스템관리",
      link: routerPath.SYSTEM_NOTICE_LIST,
      arrSub: [
        // {
        //   title: '코드관리',
        //   link: routerPath.SYSTEM_CODE,
        //   roleInfos: [
        //     {
        //       menuId: menuId.SYSTEM_CODE_READ,
        //       roleTitle: '조회',
        //     },
        //     {
        //       menuId: menuId.SYSTEM_CODE_WRITE,
        //       roleTitle: '등록',
        //     },
        //   ],
        // },
        {
          title: "취급품목",
          link: routerPath.SYSTEM_HANDLED,
          roleInfos: [
            {
              menuId: menuId.SYSTEM_HANDLED,
              roleTitle: "조회/등록",
            },
          ],
        },
        {
          title: "카테고리",
          link: routerPath.SYSTEM_CATEGORY,
          roleInfos: [
            {
              menuId: menuId.SYSTEM_CATEGORY,
              roleTitle: "조회/등록",
            },
          ],
        },
        // {
        //   title: "템플릿관리",
        //   link: routerPath.SYSTEM_TEMPLATE_LIST,
        //   roleInfos: [
        //     {
        //       menuId: menuId.SYSTEM_TEMPLATE_READ,
        //       roleTitle: "조회",
        //     },
        //     {
        //       menuId: menuId.SYSTEM_TEMPLATE_WRITE,
        //       roleTitle: "등록",
        //     },
        //   ],
        // },
        {
          title: "업체(평가)관리",
          link: routerPath.SYSTEM_PERFORMANCE_PRESENT,
          roleInfos: [
            {
              menuId: menuId.SYSTEM_PERFORMANCE_READ,
              roleTitle: "조회",
            },
            {
              menuId: menuId.SYSTEM_PERFORMANCE_WRITE,
              roleTitle: "등록",
            },
          ],
        },
        {
          title: "담당자관리",
          link: routerPath.SYSTEM_DIRECTOR_LIST,
          roleInfos: [
            {
              menuId: menuId.SYSTEM_DIRECTOR_READ,
              roleTitle: "조회",
            },
            {
              menuId: menuId.SYSTEM_DIRECTOR_WRITE,
              roleTitle: "등록",
            },
          ],
        },
        // {
        //   title: "메뉴관리",
        //   link: routerPath.SYSTEM_MENU,
        //   isSuperAdmin: true,
        //   roleInfos: [
        //     {
        //       menuId: menuId.SYSTEM_MENU_READ,
        //       roleTitle: "조회",
        //     },
        //     {
        //       menuId: menuId.SYSTEM_MENU_WRITE,
        //       roleTitle: "등록",
        //     },
        //   ],
        // },
        // {
        //   title: "결재선관리",
        //   link: routerPath.SYSTEM_MENU,
        //   roleInfos: [
        //     {
        //       menuId: menuId.SYSTEM_MENU_READ,
        //       roleTitle: "조회",
        //     },
        //     {
        //       menuId: menuId.SYSTEM_MENU_WRITE,
        //       roleTitle: "등록",
        //     },
        //   ],
        // },
        {
          title: "결재선관리",
          link: routerPath.SYSTEM_APPROVALLINE_LIST,
          roleInfos: [
            {
              menuId: menuId.SYSTEM_APPROVALLINE_READ,
              roleTitle: "조회",
              isDefault: true,
            },
            {
              menuId: menuId.SYSTEM_APPROVALLINE_WRITE,
              roleTitle: "등록",
            },
          ],
        },
        {
          title: "공지사항",
          link: routerPath.SYSTEM_NOTICE_LIST,
          roleInfos: [
            {
              menuId: menuId.SYSTEM_NOTICE_READ,
              roleTitle: "조회",
              isDefault: true,
            },
            {
              menuId: menuId.SYSTEM_NOTICE_WRITE,
              roleTitle: "등록",
            },
          ],
        },
      ],
    },
  ].filter((item) => !_.isEmpty(item));
};

function resetMenuData() {
  const resetMenuData = getMenuData();

  return resetMenuData;
}

const menuData = getMenuData();

export { menuData, resetMenuData };
