import { createRouter, createWebHistory } from "vue-router";

import store from "@/store";

import LocalStorageManager from "../LocalStorageManager";
import routerPath from "./routerPath";

import routes from "./routes";

import { ALERT_ACTION } from "@/store/modules/alert/action";
import { PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";

import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";

import { ALERT_MUTATION } from "@/store/modules/alert/mutation";
import { PROGRESS_END_MUTATION } from "@/store/modules/progressbar/mutation";
import {
  MENU_ROLE_ACTION,
  MENU_ROLE_PARTNER_ACTION,
  MENU_ROLE_SUPER_ACTION,
} from "@/store/modules/menu/action";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),

  scrollBehavior(to, from, savedPosition) {
    //  if (
    //    from.path.includes('performances') == true &&
    //    to.path.includes('partner') == true
    //  ) {
    //    return { x: 0, y: 1500 };
    //  }

    if (from.path === to.path) {
      //console.log(1111);
      return;
    }

    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  // console.log('%c=========', 'font-size:50px');
  //console.log(to, from);

  // console.log('%c///=========', 'font-size:50px');

  //console.log('%crouter.beforeEach', 'font-size:50px');
  // store.dispatch(ALERT_ACTION.HIDE_ALERT_YN);
  // store.dispatch(ALERT_ACTION.HIDE_ALERT);
  //
  // store.dispatch(PROGRESS_END_ACTION);

  store.commit(ALERT_MUTATION.HIDE_ALERT_YN_MUTATION);
  store.commit(ALERT_MUTATION.HIDE_ALERT_MUTATION);

  store.commit(PROGRESS_END_MUTATION);

  //console.log('to :>> ', to);
  const query = to.query;
  if (query.token) {
    localStorage.setItem("userData", decodeURIComponent(query.token));
    next({
      path: routerPath.HOME,
    });
    return;
  }

  const userData = LocalStorageManager.shared.getUserData();
  const token = LocalStorageManager.shared.getTokenData();
  //console.log('token :>> ', token);
  const isRequiresAuth = to.matched.some((record) => record.meta.requiresAuth !== false);
  // const isAdminDomain =
  //   window.location.hostname === 'pick.kakaosecure.net' ||
  //   window.location.hostname === 'dev-pick-dk.devel.kakao.com' ||
  //   window.location.hostname === 'pick.daumtools.com';
  const { isOnlyAdmin, isOnlyUser } = to.meta;
  //console.log("token :>> ", token, isOnlyUser);
  //console.log('getuserData :>> ', userData);
  if (!userData) {
    //console.log('유저데이터 없어요');
    if (isRequiresAuth) {
      // 관리자 또는 검수자 도메인 > 관리자 로그인 페이지로 (/vmffotvharhksfl)
      if (process.env.VUE_APP_ADMIN_WEB_ENDPOINT.indexOf(window.location.host) > -1) {
        if (process.env.NODE_ENV === "local") {
          // window.location.href =
          //   'http://localhost:8080' + routerPath.LOGIN_ADMIN;
          next({
            path: routerPath.LOGIN_ADMIN,
            query: null,
          });
        } else {
          next({
            path: routerPath.LOGIN_ADMIN,
            query: null,
          });
        }
      } else {
        next({
          path: routerPath.LOGIN,
          query: null,
        });
      }
    } else {
      //console.log('여기 타야되낟');
      //next();
      next();
    }

    return;

    //  cas login
    //  query.rl >> cas login 세션이 없으면 리턴되는 파라미터. 해당 파라미터는 사용 안해야함...
    //  if (!query.rl && !query.ticket) {
    //    let encURI =
    //      encodeURIComponent(window.location.origin + '/ke' + to.fullPath) + '';
    //    window.location.href =
    //      'https:// cas.daumkakao.com/cas/login?service=' +
    //      process.env.VUE_APP_API_ENDPOINT +
    //      '/login/cas?loginUrl=' +
    //      encURI;
    //    return;
    //  }
  }
  //else {
  // 로그인함
  const isPurchaseReirect = sessionStorage.getItem("purchaseReirect");

  if (isPurchaseReirect) {
    sessionStorage.removeItem("purchaseReirect");
  }
  const isReviewStatus = LocalStorageManager.shared.getIsReviewStatus();
  if (isReviewStatus && to.path !== routerPath.MY_INFO) {
    next({
      path: routerPath.MY_INFO,
    });
    return;
  }

  const isAdmin = LocalStorageManager.shared.getIsAdmin();
  const hasAdmin = LocalStorageManager.shared.hasAdmin();
  const isPartner = LocalStorageManager.shared.getIsPartner();

  //console.log('isAdmin :>> ', isAdmin, to.path);
  //console.log('hasAdmin :>> ', hasAdmin, to.path);
  //console.log('isPartner :>> ', isPartner, to.path);

  // console.log('로그인함', next());

  if ((isAdmin && isOnlyUser) || (!isAdmin && isOnlyAdmin)) {
    next({
      path: routerPath.HOME,
    });
    return;
  }

  // 이미 로그인 상태인데, (회원가입, 로그인) 화면으로 이동하려 한다. -> 홈으로 이동.
  const cantGoPageWithUserData =
    to.path === routerPath.JOIN ||
    to.path === routerPath.JOIN_INQUIRY ||
    to.path === routerPath.LOGIN ||
    to.path === routerPath.LOGIN_ADMIN;
  //console.log('cantGoPageWithUserData :>> ', cantGoPageWithUserData);
  if (userData && cantGoPageWithUserData) {
    next({
      path: routerPath.HOME,
    });
    return;
  }

  if (!isPartner) {
    if (to.path === routerPath.HOME) {
      if (from.path !== routerPath.SYSTEM_NOTICE_LIST) {
        next({
          path: routerPath.SYSTEM_NOTICE_LIST,
        });
      } else {
        next(false);
        document.location = "/";
      }
      return;
    }
  }
  if (isPartner) {
    // 파트너임
    //console.log('여기타야됨', to.path);
    if (to.path === routerPath.HOME) {
      if (from.path !== routerPath.SYSTEM_NOTICE_LIST) {
        await router.push(routerPath.SYSTEM_NOTICE_LIST);
      } else {
        document.location = "/";
      }
      //  next({
      //    path: routerPath.SYSTEM_NOTICE_LIST,
      //  });
      return;
    }
    //  else {
    //    next();
    //    return;
    //  }
  }
  const isFinance = LocalStorageManager.shared.getIsFinance();
  const isInspect = LocalStorageManager.shared.getIsInspect();
  const isUser = LocalStorageManager.shared.getIsUser();
  const isSuper = LocalStorageManager.shared.getIsSuper();
  // console.log('@@@@@', !!userData, 'a', isAdmin, 'i', isInspect, 'u', isUser);
  if (userData) {
    if (isPartner) {
      await store.dispatch(MENU_ROLE_PARTNER_ACTION);
    } else if (isSuper) {
      await store.dispatch(MENU_ROLE_SUPER_ACTION);
    } else if (isAdmin || isInspect || isUser || isFinance) {
      await store.dispatch(MENU_ROLE_ACTION);
    }
  }

  const { menuId } = to.meta;
  //console.log('routerMenuID', to, menuId);

  // //  로그인 같이 메뉴에 속하지 않는 페이지.
  // if (!menuId) {
  //   next();
  //   return;
  // }

  //  메뉴 데이터에 없는 메뉴 가려고 하면 홈으로.

  const isValidMenu = getIsValidMenu(menuId);
  // console.log('%cROUTERmenuData :>> ', 'font-size:30px', menuId, isValidMenu);

  if (!isValidMenu) {
    console.log("menuId :>> ", menuId);
    if (menuId === "myInfo") {
      next();
      return;
    }
    console.error("권한이 없습니다.", to);
    console.error("권한이 없습니다.", from);
    console.error("권한이 없습니다.", from.path ? from.path : routerPath.HOME);

    if (to.path === routerPath.HOME && to.path === routerPath.HOME) {
      // alert('접근 권한이 없습니다.');
      next(false);
    } else {
      router
        .push({
          path: from.path ? from.path : routerPath.HOME,
        })
        .catch((e) => {
          console.error(e);
          // alert('접근 권한이 없습니다.');
        });
    }

    return;
  }

  next();
  //}
});

const getIsValidMenu = (menuId, _menus) => {
  // console.log('%cmenuId', 'font-size:50px', menuId, menus);
  // const ret = menus.some((item) => {
  //   let boolean = false;
  //   if (!item.arrSub) {
  //     // 서브 없을떄
  //     boolean = item.menuId.some((itemMenuId) => {
  //       return itemMenuId === menuId;
  //     });
  //   } else {
  //     // 서브 메뉴 있을때
  //     boolean = item.arrSub.some((subItem) => {
  //       return subItem.menuId.some((subItemMenuId) => {
  //         return subItemMenuId === menuId;
  //       });
  //     });
  //   }
  //   return boolean;
  // });
  const roleList = store.getters["getRoleList"];
  // console.log(
  //   '%croleList',
  //   'font-size:50px',
  //   roleList,
  //   '/',
  //   menuId,
  //   roleList.findIndex((item) => item === menuId) > -1,
  // );
  return roleList.findIndex((item) => item === menuId) > -1;
};

export default router;
