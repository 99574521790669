const paymentRouterPath = {
  PAYMENT: `/payment`,
  PAYMENT_VIEW_DETAIL: "/payment/draftViewDetail",
  PAYMENT_WRITE: "/payment/draft",
  PAYMENT_MODIFY: "/payment/tempDraft",

  PAYMENT_DRAFT_TEMP: "/payment/draftTempDocs",
  PAYMENT_DRAFT_ING: "/payment/draftIngDocs",
  PAYMENT_DRAFT_COMPLETE: "/payment/draftCompleteDocs",
  PAYMENT_DRAFT_REJECT: "/payment/draftRejectDocs",

  PAYMENT_APPROVAL_BEFORE: "/payment/apprBeforeDocs", //결재전 문서함
  PAYMENT_APPROVAL_RECEIVE: "/payment/apprReceiveDocs", //승인할 문서함
  PAYMENT_APPROVAL_ING: "/payment/apprIngDocs", //승인한 문서함
  PAYMENT_APPROVAL_COMPLETE: "/payment/apprCompleteDocs", //완료된 문서함
  PAYMENT_APPROVAL_REJECT: "/payment/apprRejectDocs", //반려한 문서함

  PAYMENT_CC_ING: "/payment/ccIngDocs",
  PAYMENT_CC_COMPLETE: "/payment/ccCompleteDocs",

  PAYMENT_DRAFT: "/payment/draftTempDocs",

  PAYMENT_DRAFT_ING_DETAIL: "/payment/draftIngDetail", //진행중문서함
  PAYMENT_DRAFT_COMPLETE_DETAIL: "/payment/draftCompleteDetail", //완료된문서함
  PAYMENT_DRAFT_REJECT_DETAIL: "/payment/draftRejectDetail", //반려된문서함
  PAYMENT_DRAFT_REJECT_EDIT: "/payment/draftRejectEdit", //사용안함

  PAYMENT_APPROVAL_BEFORE_DETAIL: "/payment/apprBeforeDetail", //결재전문서함
  PAYMENT_APPROVAL_RECEIVE_DETAIL: "/payment/apprReceiveDetail", //승인할문서함
  PAYMENT_APPROVAL_ING_DETAIL: "/payment/apprIngDetail", //승인한문서함
  PAYMENT_APPROVAL_COMPLETE_DETAIL: "/payment/apprCompleteDetail", //완료된문서함
  PAYMENT_APPROVAL_REJECT_DETAIL: "/payment/apprRejectDetail", //반려한문서함

  PAYMENT_CC_ING_DETAIL: "/payment/ccIngDetail", //참조
  PAYMENT_CC_COMPLETE_DETAIL: "/payment/ccCompleteDetail", //처리된문서함

  PAYMENT_SYSTEM: `/payment/system`, //관리
  PAYMENT_SYSTEM_PUBLIC_LINE_LIST: "/payment/system/publicLine", //결재선리스트
  PAYMENT_SYSTEM_PUBLIC_LINE_WRITE: "/payment/system/publicLineEdit", //결재선리스트

  PAYMENT_SYSTEM_ACCOUNT_LIST: "/payment/system/payAccountList",
  PAYMENT_SYSTEM_ACCOUNT_WRITE: "/payment/system/payAccountEdit",
  PAYMENT_SYSTEM_USE_GUIDE_LIST: "/payment/system/useGuideList", //guide리스트
  PAYMENT_SYSTEM_USE_GUIDE_WRITE: "/payment/system/useGuideEdit", //guide리스트
};

export default paymentRouterPath;
