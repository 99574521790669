import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import modules, { ALERT_ACTION, ESTIMATE_LIST_ACTION } from "./modules";

const store = createStore({
  modules: {
    ...modules,
  },
  plugins: [createPersistedState()],
  state: {},
  mutations: {},
  actions: {
    // 비동기 처리
  },
});
export default store;

export { ALERT_ACTION, ESTIMATE_LIST_ACTION };
