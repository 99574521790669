import Vue from "vue";

const CommStore = {
  namespaced: true,
  state: {
    service: {
      name: "KEP",
      // logo: 'logo_account.png',
      // logoWidth: 104,
      // logoHeight: 17,
      loginLogo: "logo_buy_login.png",
      loginLogoWidth: 213,
      loginLogoHeight: 25,
      // loginUrl: `${LOGIN_URL}/auth/login`,
      // logoutUrl: `${API_ENDPOINT}/auth/logout`,
      // loginUrl: `${LOGIN_URL}`,
      // logoutUrl: `${API_ENDPOINT}/logout`,
      rootDeptCode: "#",
    },
    company: {
      name: "KEP",
      logo: "",
      logoWidth: 147,
      logoHeight: 22,
      loginLogo: "",
      loginLogoWidth: 300,
      loginLogoHeight: 43,
    },
    cas: {},
    login: {
      cmpCode: "dkt",
      empNo: "",
      empName: "",
      accountId: "",
      deptName: "",
      deptCode: "",
      officeLocation: "",
      isAdmin: false,
      email: "",
    },
    layer: {
      order: 0,
      first: {
        order: 1,
        type: "alert",
        isShow: false,
        header: "",
        content: "",
        componentFile: "",
        class: "",
        style: "",
        props: {},
      },
      second: {
        order: 2,
        type: "alert",
        isShow: false,
        header: "",
        content: "",
        componentFile: "",
        class: "",
        style: "",
        props: {},
      },
    },
    toast: {
      isShow: false,
      content: "",
      class: "",
      timer: 2000,
    },
    menu: {
      openMenu: "",
      foldMenu: [],
    },
  },
  mutations: {
    showLayer(state, data) {
      state.layer.order = 1;
      state.layer.first.isShow = true;
      state.layer.first.type = data.type;
      state.layer.first.header = data.header;
      state.layer.first.content = data.content;
      state.layer.first.componentFile = data.componentFile;
      state.layer.first.class = data.class;
      state.layer.first.style = data.style;
      state.layer.first.props = data.props;
    },
    closeLayer(state) {
      state.layer.order = 1;
      state.layer.first.isShow = false;
      state.layer.first.type = "";
      state.layer.first.header = "";
      state.layer.first.content = "";
      state.layer.first.componentFile = "";
      state.layer.first.class = "";
      state.layer.first.style = "";
      state.layer.first.props = {};
    },
    showLayer2(state, data) {
      state.layer.order = 2;
      state.layer.second.isShow = true;
      state.layer.second.type = data.type;
      state.layer.second.header = data.header;
      state.layer.second.content = data.content;
      state.layer.second.componentFile = data.componentFile;
      state.layer.second.class = data.class;
      state.layer.second.style = data.style;
      state.layer.second.props = data.props;
    },
    closeLayer2(state) {
      state.layer.order = 1;
      state.layer.second.isShow = false;
      state.layer.second.type = "";
      state.layer.second.header = "";
      state.layer.second.content = "";
      state.layer.second.componentFile = "";
      state.layer.second.class = "";
      state.layer.second.style = "";
      state.layer.second.props = {};
    },
    login(state, data) {
      // Vue.set(state.login, "empNo", data.empNo);
      // Vue.set(state.login, "empName", data.empName);
      // Vue.set(state.login, "accountId", data.accountId);
      // Vue.set(state.login, "deptName", data.deptName);
      // Vue.set(state.login, "deptCode", data.deptCode);
      // Vue.set(state.login, "officeLocation", data.officeLocation);
      // Vue.set(state.login, "isAdmin", data.isAdmin);
      // Vue.set(state.login, "deptPathName", data.deptPathName);
      // Vue.set(state.login, "mobile", data.mobile);
      // Vue.set(state.login, "email", data.email);

      state.login.empNo = data.empNo;
      state.login.empName = data.empName;
      state.login.accountId = data.accountId;
      state.login.deptName = data.deptName;
      state.login.deptCode = data.deptCode;
      state.login.officeLocation = data.officeLocation;
      state.login.isAdmin = data.isAdmin;
      state.login.deptPathName = data.deptPathName;
      state.login.mobile = data.mobile;
      state.login.email = data.email;
    },
    showToast(state, data) {
      state.toast.isShow = true;
      state.toast.content = data.content;
      if (data.class) {
        state.toast.class = data.class;
      } else {
        state.toast.class = "align_left";
      }
      if (data.timer) {
        state.toast.timer = data.timer;
      } else {
        state.toast.timer = 2000;
      }
    },
    closeToast(state) {
      state.toast.isShow = false;
      state.toast.content = "";
      state.toast.class = "align_left";
      state.toast.timer = 2000;
    },
    openMenu(state, data) {
      state.menu.openMenu = data.menuItem;
      state.menu.foldMenu = [];
      data.foldItem.forEach((item) => {
        state.menu.foldMenu.push(item);
      });
    },
  },
  getters: {
    getLayer: (state) => {
      if (state.layer.order === 2) {
        return state.layer.second;
      }
      if (state.layer.order === 0) {
        return "";
      }
      return state.layer.first;
    },
    getFirstLayer: (state) => state.layer.first,
    getLoginData: (state) => state.login,
  },
  actions: {
    // 비동기 처리
  },
};

export default CommStore;
