import { App as Application } from "vue";
import {
  addCommaForMoney,
  assetStatusName,
  cardNumber,
  corporateNum,
  corporateOrPersonalNum,
  currency,
  date,
  dateStringFormat,
  dateTimeStringFormat,
  dateTimeStringFormatIfNullDash,
  dateTimeStringKoreanFormat,
  dateToStringHasDiv,
  dateYm,
  departmentText,
  categoryText,
  employee,
  idWithName,
  idWithNameByObj,
  idWithNameIfNullDash,
  monthValue,
  nullToDash,
  orgTreeNode,
  percentValue,
  personalNum,
  replaceNlbr,
  strDateTimeSplit,
  time,
  toInteger,
  taxInvoceNumber,
} from "./filters";
export default {
  install(app: Application) {
    app.config.globalProperties.$filters = {
      addCommaForMoney,
      assetStatusName,
      cardNumber,
      corporateNum,
      corporateOrPersonalNum,
      currency,
      date,
      dateStringFormat,
      dateTimeStringFormat,
      dateTimeStringFormatIfNullDash,
      dateTimeStringKoreanFormat,
      dateToStringHasDiv,
      dateYm,
      departmentText,
      categoryText,
      employee,
      idWithName,
      idWithNameByObj,
      idWithNameIfNullDash,
      monthValue,
      nullToDash,
      orgTreeNode,
      percentValue,
      personalNum,
      replaceNlbr,
      strDateTimeSplit,
      time,
      toInteger,
      taxInvoceNumber,
    };
    /*
    Vue.filter("currency", currency);
    Vue.filter("cardNumber", cardNumber);
    Vue.filter("date", date);
    Vue.filter("dateYm", dateYm);
    Vue.filter("time", time);
    Vue.filter("employee", employee);
    Vue.filter("orgTreeNode", orgTreeNode);
    Vue.filter("toInteger", toInteger);
    Vue.filter("idWithNameByObj", idWithNameByObj);
    Vue.filter("idWithName", idWithName);
    Vue.filter("idWithNameIfNullDash", idWithNameIfNullDash);
    Vue.filter("dateTimeStringFormat", dateTimeStringFormat);
    Vue.filter("dateTimeStringFormatIfNullDash", dateTimeStringFormatIfNullDash);
    Vue.filter("dateTimeStringKoreanFormat", dateTimeStringKoreanFormat);
    Vue.filter("departmentText", departmentText);
    Vue.filter("categoryText", categoryText);
    Vue.filter("percentValue", percentValue);
    Vue.filter("monthValue", monthValue);
    Vue.filter("replaceNlbr", replaceNlbr);
    Vue.filter("addCommaForMoney", addCommaForMoney);
    Vue.filter("corporateNum", corporateNum);
    Vue.filter("personalNum", personalNum);
    Vue.filter("corporateOrPersonalNum", corporateOrPersonalNum);
    Vue.filter("dateStringFormat", dateStringFormat);
    Vue.filter("strDateTimeSplit", strDateTimeSplit);
    Vue.filter("dateToStringHasDiv", dateToStringHasDiv);
    Vue.filter("nullToDash", nullToDash);
    Vue.filter("assetStatusName", assetStatusName);
    Vue.filter("taxInvoceNumber", taxInvoceNumber);
    */
  },
};

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    $filters: any;
  }
}

// import * as filters from './filters.js';
// export default {
//   install(Vue) {
//     Object.keys(filters).forEach(function (key) {
//       Vue.filter(key, filters[key]);
//     });
//   }
// }
