import {
  GET_INSPECT_STATUS_LIST,
  GET_INSPECT_KEYWORD_LIST,
  INSPECT_LIST_ACTION,
  INSPECT_WRITE_ACTION,
} from "./action";
import { INSPECT_STATUS_LIST_MUTATION, INSPECT_KEYWORD_LIST_MUTATION } from "./mutation";
import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";

export default {
  state: {
    statusList: [],
    keywordList: [],
  },
  actions: {
    async [GET_INSPECT_STATUS_LIST](context) {
      if (context.state.statusList.length > 0) return; // 한번만 호출.
      const result = await ApiService.shared.getData(`${apiPath.INSPECT}/status`);
      const { code, data, text } = result;
      this.commit({
        type: INSPECT_STATUS_LIST_MUTATION,
        list: data || [],
      });
    },
    async [GET_INSPECT_KEYWORD_LIST](context) {
      if (context.state.keywordList.length > 0) return; // 한번만 호출.

      //const result = await ApiService.shared.getData(apiPath.ITEM_STATUS_LIST); //API가 없어서 front에서 하드코딩함
      const result = {
        code: "",
        data: [
          { id: "projectName", code: "projectName", desc: "프로젝트명" },
          { id: "companyName", code: "companyName", desc: "파트너사" },
          { id: "title", code: "title", desc: "발주명" },
          { id: "orderCid", code: "orderCid", desc: "발주번호" },
          { id: "inspectNum", code: "inspectNum", desc: "검수번호" },
          { id: "purchase", code: "purchase", desc: "구매담당자" },
          { id: "inspect", code: "inspect", desc: "검수담당자" },
        ],
        text: "",
      };
      const { code, data, text } = result;
      this.commit({
        type: INSPECT_KEYWORD_LIST_MUTATION,
        list: data || [],
      });
    },
    async [INSPECT_LIST_ACTION](context) {
      await context.dispatch(GET_INSPECT_STATUS_LIST);
      await context.dispatch(GET_INSPECT_KEYWORD_LIST);
    },
    async [INSPECT_WRITE_ACTION](context) {
      //
    },
  },
  mutations: {
    [INSPECT_STATUS_LIST_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const { list } = payload;
      state.statusList = Object.freeze(
        list.map((item, index) => Object.freeze({ ...item, index: index })),
      );
    },
    [INSPECT_KEYWORD_LIST_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const { list } = payload;
      state.keywordList = Object.freeze(
        list.map((item, index) => Object.freeze({ ...item, index: index })),
      );
    },
  },
};
