import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";
import * as ACTION_GET from "./action";
import * as MUTATION from "./mutation";

export default {
  state: {
    statusList: [], //정산서상태리스트
    bankCode: [], //은행코드
    billType: [], //계산서 종류
    currency: [], //통화
    evidenceType: [], //정산서증빙유형
    deductionType: [], //매입세액공제유형
    taxType: [], //정산서 매입세액 상세유형
    modTaxBillCode: [], //세금계산서 수정코드
    taxBill: [], // 전자세금계산서 종류
    taxBillTypes: [], //계산서종류  ( 전자세금계산서 + 계산서 종류)
    taxCode: [], //세금코드
    paymentMethod: [], //지급방법리스트
  },
  actions: {
    async [ACTION_GET.SETTLEMENT_STATUS](context) {
      if (context.state.statusList.length > 0) return; // 한번만 호출.
      const result = await ApiService.shared.getData(apiPath.KEP_CMMN_SETTLEMENT_STATUS);
      const { data = [] } = result;
      if (data && data.length > 0) {
        context.commit({
          type: MUTATION.SETTLEMENT_STATUS,
          list: data,
        });
      } else {
        context.commit({
          type: MUTATION.SETTLEMENT_STATUS,
          list: [],
        });
      }
    },
    async [ACTION_GET.BANKCODE](context) {
      if (context.state.bankCode.length > 0) return; // 한번만 호출.
      const result = await ApiService.shared.getData(apiPath.KEP_CMMN_BANKCODE);
      const { data = [] } = result;
      if (data && data.length > 0) {
        context.commit({
          type: MUTATION.BANKCODE,
          list: data,
        });
      } else {
        context.commit({
          type: MUTATION.BANKCODE,
          list: [],
        });
      }
    },
    async [ACTION_GET.BILLTYPE](context) {
      if (context.state.billType.length > 0) return; // 한번만 호출.
      const result = await ApiService.shared.getData(apiPath.KEP_CMMN_BILLTYPE);
      const { data = [] } = result;
      if (data && data.length > 0) {
        context.commit({
          type: MUTATION.BILLTYPE,
          list: data,
        });
      } else {
        context.commit({
          type: MUTATION.BILLTYPE,
          list: [],
        });
      }
    },
    async [ACTION_GET.CURRENCY](context) {
      if (context.state.currency.length > 0) return; // 한번만 호출.
      const result = await ApiService.shared.getData(apiPath.KEP_CMMN_CURRENCY);
      const { data = [] } = result;
      if (data && data.length > 0) {
        context.commit({
          type: MUTATION.CURRENCY,
          list: data,
        });
      } else {
        context.commit({
          type: MUTATION.CURRENCY,
          list: [],
        });
      }
    },
    async [ACTION_GET.EVIDENCETYPE](context) {
      if (context.state.evidenceType.length > 0) return; // 한번만 호출.
      const result = await ApiService.shared.getData(apiPath.KEP_CMMN_EVIDENCETYPE);
      const { data = [] } = result;
      if (data && data.length > 0) {
        context.commit({
          type: MUTATION.EVIDENCETYPE,
          list: data,
        });
      } else {
        context.commit({
          type: MUTATION.EVIDENCETYPE,
          list: [],
        });
      }
    },
    async [ACTION_GET.DEDUCTIONTYPE](context) {
      if (context.state.deductionType.length > 0) return; // 한번만 호출.
      const result = await ApiService.shared.getData(apiPath.KEP_CMMN_DEDUCTIONTYPE);
      const { data = [] } = result;
      if (data && data.length > 0) {
        context.commit({
          type: MUTATION.DEDUCTIONTYPE,
          list: data,
        });
      } else {
        context.commit({
          type: MUTATION.DEDUCTIONTYPE,
          list: [],
        });
      }
    },
    async [ACTION_GET.TAXTYPE](context) {
      if (context.state.taxType.length > 0) return; // 한번만 호출.
      const result = await ApiService.shared.getData(apiPath.KEP_CMMN_TAXTYPE);
      const { data = [] } = result;
      if (data && data.length > 0) {
        context.commit({
          type: MUTATION.TAXTYPE,
          list: data,
        });
      } else {
        context.commit({
          type: MUTATION.TAXTYPE,
          list: [],
        });
      }
    },
    async [ACTION_GET.MODTAXBILLCODE](context) {
      if (context.state.modTaxBillCode.length > 0) return; // 한번만 호출.
      const result = await ApiService.shared.getData(apiPath.KEP_CMMN_MODTAXBILLCODE);
      const { data = [] } = result;
      if (data && data.length > 0) {
        context.commit({
          type: MUTATION.MODTAXBILLCODE,
          list: data,
        });
      } else {
        context.commit({
          type: MUTATION.MODTAXBILLCODE,
          list: [],
        });
      }
    },
    async [ACTION_GET.TAXBILL](context) {
      if (context.state.taxBill.length > 0) return; // 한번만 호출.
      const result = await ApiService.shared.getData(apiPath.KEP_CMMN_TAXBILL);
      const { data = [] } = result;
      if (data && data.length > 0) {
        context.commit({
          type: MUTATION.TAXBILL,
          list: data,
        });
      } else {
        context.commit({
          type: MUTATION.TAXBILL,
          list: [],
        });
      }
    },
    async [ACTION_GET.TAXBILLTYPES](context) {
      if (context.state.taxBillTypes.length > 0) return; // 한번만 호출.
      const result = await ApiService.shared.getData(apiPath.KEP_CMMN_TAXBILLTYPES);
      const { data = [] } = result;
      if (data && data.length > 0) {
        context.commit({
          type: MUTATION.TAXBILLTYPES,
          list: data,
        });
      } else {
        context.commit({
          type: MUTATION.TAXBILLTYPES,
          list: [],
        });
      }
    },

    async [ACTION_GET.TAXCODE](context) {
      if (context.state.taxCode.length > 0) return; // 한번만 호출.
      const result = await ApiService.shared.getData(apiPath.KEP_CMMN_TAXCODE);
      const { data = [] } = result;
      if (data && data.length > 0) {
        context.commit({
          type: MUTATION.TAXCODE,
          list: data,
        });
      } else {
        context.commit({
          type: MUTATION.TAXCODE,
          list: [],
        });
      }
    },

    async [ACTION_GET.PAYMENTMETHOD](context) {
      if (context.state.paymentMethod.length > 0) return; // 한번만 호출.
      const result = await ApiService.shared.getData(apiPath.KEP_SAP_EXPENDITURE_PAYMENTMETHOD);
      const { data = [] } = result;
      if (data && data.length > 0) {
        context.commit({
          type: MUTATION.PAYMENTMETHOD,
          list: data,
        });
      } else {
        context.commit({
          type: MUTATION.PAYMENTMETHOD,
          list: [],
        });
      }
    },

    async [ACTION_GET.SETTLMENT_COMM](context) {
      await context.dispatch(ACTION_GET.EVIDENCETYPE);
      await context.dispatch(ACTION_GET.DEDUCTIONTYPE);
      await context.dispatch(ACTION_GET.TAXTYPE);
      await context.dispatch(ACTION_GET.BANKCODE);
      await context.dispatch(ACTION_GET.BILLTYPE);
      await context.dispatch(ACTION_GET.CURRENCY);
      await context.dispatch(ACTION_GET.MODTAXBILLCODE);
      await context.dispatch(ACTION_GET.TAXBILL);
      // await context.dispatch(ACTION_GET.TAXBILLTYPES);
      // await context.dispatch(ACTION_GET.TAXCODE);
      await context.dispatch(ACTION_GET.PAYMENTMETHOD);
    },
  },
  mutations: {
    [MUTATION.SETTLEMENT_STATUS](state, payload) {
      const { list } = payload;
      state.statusList = Object.freeze(list.map((item, index) => Object.freeze({ ...item })));
    },

    [MUTATION.BANKCODE](state, payload) {
      const { list } = payload;
      state.bankCode = Object.freeze(list.map((item, index) => Object.freeze({ ...item })));
    },
    [MUTATION.BILLTYPE](state, payload) {
      const { list } = payload;
      state.billType = Object.freeze(list.map((item, index) => Object.freeze({ ...item })));
    },
    [MUTATION.CURRENCY](state, payload) {
      const { list } = payload;
      state.currency = Object.freeze(list.map((item, index) => Object.freeze({ ...item })));
    },
    [MUTATION.EVIDENCETYPE](state, payload) {
      const { list } = payload;
      state.evidenceType = Object.freeze(list.map((item, index) => Object.freeze({ ...item })));
    },
    [MUTATION.DEDUCTIONTYPE](state, payload) {
      const { list } = payload;
      state.deductionType = Object.freeze(
        list.map((item, index) => Object.freeze({ ...item, codeKey: item.code.substring(0, 2) })),
      );
    },
    [MUTATION.TAXTYPE](state, payload) {
      const { list } = payload;
      state.taxType = Object.freeze(
        list.map((item, index) => Object.freeze({ ...item, codeKey: item.code.substring(0, 4) })),
      );
    },

    [MUTATION.MODTAXBILLCODE](state, payload) {
      const { list } = payload;
      state.modTaxBillCode = Object.freeze(list.map((item, index) => Object.freeze({ ...item })));
    },
    [MUTATION.TAXBILL](state, payload) {
      const { list } = payload;
      state.taxBill = Object.freeze(list.map((item, index) => Object.freeze({ ...item })));
    },
    [MUTATION.TAXBILLTYPES](state, payload) {
      const { list } = payload;
      state.taxBillTypes = Object.freeze(list.map((item, index) => Object.freeze({ ...item })));
    },
    [MUTATION.TAXCODE](state, payload) {
      const { list } = payload;
      state.taxCode = Object.freeze(list.map((item, index) => Object.freeze({ ...item })));
    },
    [MUTATION.PAYMENTMETHOD](state, payload) {
      const { list } = payload;
      state.paymentMethod = Object.freeze(
        list.map((item, index) =>
          Object.freeze({ ...item, code: item.pymtMethod, codeName: item.pymtMethodName }),
        ),
      );

      //pymtMethod//pymtMethodName
    },
  },
};
