const aprvlApi =
  process.env.NODE_ENV === "local" || process.env.NODE_ENV === "local.lig"
    ? process.env.VUE_APP_APRVL_API_ENDPOINT
    : ``;
const $isLocal = process.env.NODE_ENV === "local";

const aprvlApiPath = {
  // 00 공통
  APRVL_DOC_STATUS: `${aprvlApi}/aprvl/cmmn/aprvlDocStatus`,
  APRVL_LINE_TYPE: `${aprvlApi}/aprvl/cmmn/aprvlLineType`,
  APRVL_STATUS: `${aprvlApi}/aprvl/cmmn/aprvlStatus`,
  APRVL_DLGT_TYPE: `${aprvlApi}/aprvl/cmmn/aprvlDlgtType`,
  APRVL_GROUP_CODE: `${aprvlApi}/aprvl/cmmn/groupCode`,
  APRVL_TAR_GUBUN: `${aprvlApi}/aprvl/cmmn/tarGubun`,

  // 01 조직도 코드
  APRVL_ORG_CODE: `${aprvlApi}/aprvl/orgCode`,

  // 02 조직도 사원
  APRVL_ORG_EMP: `${aprvlApi}/aprvl/orgCode`,
  // 조직도 사원 리스트 정보를 조회
  APRVL_ORGEMP: `${aprvlApi}/aprvl/orgEmp`,

  // 03 조직도 부서
  APRVL_ORGDEPT: `${aprvlApi}/aprvl/orgDept`,
  APRVL_ORGDEPT_TREE: `${aprvlApi}/aprvl/orgDept/tree`,

  // 04 조직도 사원 겸직
  APRVL_ORG_EMP_CONC: `${aprvlApi}/aprvl/orgEmpConc`,

  // 05 결재 문서
  APRVL_DOC_LIST: `${aprvlApi}/aprvl/aprvlDoc`,
  APRVL_DOC_DETAIL: `${aprvlApi}/aprvl/aprvlDoc/detail`,
  APRVL_DOC_DFLT_INFO: `${aprvlApi}/aprvl/aprvlDoc/dfltInfo`, // 결재 문서 기본 정보 조회
  APRVL_DOC_COUNT: `${aprvlApi}/aprvl/aprvlDoc/count`, // 문서함별 총 리스트 갯수

  // 06 결재선
  APRVL_LINE: `${aprvlApi}/aprvl/aprvlLine`,
  APRVL_LINE_PROC: `${aprvlApi}/aprvl/aprvlLine/aprvlProc`, // 결재 선 - 결재 처리
  APRVL_LINE_PROC_RCV: `${aprvlApi}/aprvl/aprvlLine/aprvlProc/recovery`, // 결재 선 - 문서원복 (최종결재자 미승인 처리)

  // 07 결재 문서 읽음
  APRVL_DOC_READ: `${aprvlApi}/aprvl/aprvlDocRead`,

  // 08 결재 대결
  APRVL_DLGT: `${aprvlApi}/aprvl/aprvlDlgt`,
  // 위임

  // 09 공용 결재 선
  APRVL_PBLC_APRVL_LINE: `${aprvlApi}/aprvl/pblcAprvlLine`,

  // 10 결재 선 계정
  APRVL_LINE_ACCONT: `${aprvlApi}/aprvl/aprvlLineAccnt`,

  // 11 가이드
  APRVL_GUIDE: `${aprvlApi}/aprvl/guide`,

  // 99 공통 결재 선
  CMMN_APRVL_LINE: `${aprvlApi}/aprvl/cmmnAprvlLine`,

  _SETTELMENT_CID: `${aprvlApi}/jde//aprvl`,

  //신규
  //위임
  APRVL_DELEGATE: `${aprvlApi}/aprvl/aprvlLine/delegate`,

  //http://localhost:18082/api/aprvl/aprvlDoc
};

const apiPath = {
  ...aprvlApiPath,
  //...tKoopApiPath,

  APRVL_ORGDEPT_READTREE: `${aprvlApi}/aprvl/cmmn/readTree`,
  APRVL_ORGDEPT_REMAKETREE: `${aprvlApi}/aprvl/cmmn/remakeTree`,
  //로그인
  LOGIN: `/login`,
  /**
   *  PASSWORD_CHANGE
   * PUT - '/api/auth/{accountId}'[운영사] 비밀번호 변경
   * @body {
      "oldPassword": "string",
      "newPassword": "string" // 초기화 상태시 안넘김
    } 
  *
   */
  PASSWORD_CHANGE: `/auth`,

  // 01. 공통 코드
  ANNOUNCE_STATUS_LIST: "/post/status/code",
  BID_STATUS_LIST: "/tender/status/code",
  ORDER_STATUS_LIST: "/order/status/code",
  PARTNER_STATUS_LIST: "/partner/status/code",
  ACCOUNT_STATUS_LIST: "/account/status/code",
  DRAFT_STATUS_LIST: "/purchaseDraft/status",
  CONTRACT_STATUS_LIST: "/contract/status",
  CONTRACT_TYPE_LIST: "/contract/type",
  CONTRACT_PART_LIST: "/contract/part",
  CONTRACT_REDUCE_LIST: "/contract/reduce", // 절감 기준.
  CONTRACT_CALCULATE_RANGE_LIST: "/contract/calulate/range", //calulate 오타.
  CONTRACT_CALCULATE_TYPE_LIST: "/contract/calulate/type", // 정산 여부. calulate 오타.
  CONTRACT_PARTNER_INSPECT_TYPE_LIST: "/contract/partner/inspect/type", // 파트너사 검수 상태
  CONTRACT_CONDITION_TYPE_LIST: "/contract/condition/type", // 계약 형태.
  PARTNER_BUSINESS_TYPE: "/partner/business/type",
  PARTNER_GUBUNS: "/partner/gubun", // 파트너 구분.
  CONTRACT_COMPANY_CODE: "/contract/companyCode", // 계약 당사자 회사코드
  EVALUATE_PROGRESS_STATUS_LIST: "/evaluate/progress/status", //수행도평가 진행 상태

  MOBILE_STATION: "/mobile/station",
  PHONE_AREA: "/phone/area",
  EMAIL_VENDOR: "/email/vendor",
  CURRENCY: "/currency",
  EXCEPTION_TEST: "/exception",
  PAGE_RANGE: "/pagerange",
  QUANTITY: "/quantity",
  BANK_CODE: "/bankcode",
  DOMAIN: "/domain",
  //TEMPLATE_GUBUN:'/contract/template/gubun',     // 템플릿 구분
  TEMPLATE_GUBUN: "/template/templateGubun", // 템플릿 구분
  // PROJECT_CODE: "/project/code",
  PROJECT_CODE: "/kep/sap/projectCode",

  // 02. 공고
  ANNOUNCE: "/post",
  CREATE_ANNOUNCE_NUMBER: "/post/id",

  // 03. 입찰
  BID: "/tender",
  BID_PARTNERS: "/tender/evaluate", // 입찰 선정 업체 조회

  // 04. 발주
  ORDER: "/order",
  CREATE_ORDER_NUMBER: "/order/id",
  ORDER_SEND_DOC: "/order/sendOrderDoc",

  ORDER_PARTNER_SIGN: "/sign/partner/order", //파트너사 계약 전자서명 API POST obj = {cid, sigendData}
  ORDER_MANAGER: "/order/manager",

  // 05. 카테고리
  CATEGORY: "/category",

  // 06. 공지
  NOTICE: "/notice",
  NOTICE_MAIN: "/notice/main",

  // 07. 파트너 관리
  PARTNER: "/partner",
  PARTNER_JOIN_INQUIRY: "/partner/join",
  PARTNER_PERSON_LIST: "/partner/person",
  PARTNER_PERSON_SEARCH_LIST: "/partner/search",
  PARTNER_CREDIT_EVALUATE: "/partner/evaluate",
  PARTNER_ADMIN: "/partner/admin",

  // 08. 토큰
  TOKEN: "/token",

  // 09. 이용 약관
  TERM: "/terms",
  TERM_COMPANY: "/terms/company",

  // 10. 담당자 관리
  MANAGER: "/manager",
  MANAGER_EXIST: "/manager/exist",
  MANAGER_LDAP: "/manager/ldap",
  MANAGER_OWNER: "/manager/owner", ///manager/owner/${id}

  // 11. 첨부 파일
  ATTACH_FILE: "/attach",

  // 12. 메일
  MAIL_CONFIRM: "/external/mail/mailConfirm", // 메일 인증.
  MAIL_WITH_KAKAO: "/mail/withKakao", // 카카오 파트너 가입요청.
  MAIL_PASS_RESET: "/mail/passReset", // 관리자 -> 파트너 의 임시비밀번호 발송
  MAIN_PARTNER_PASS_RESET: "/mail/partnerPassReset", // 메인화면 비밀번호 찾기

  // 14. 거래현황
  RECEIVE: "/receive",

  // 15. 공동체
  COMPANY: "/company",

  // 17. 계약
  CONTRACT: "/contract",
  CONTRACT_SEND_DOC: "/contract/sendContractDoc",
  CONTRACT_PRICE: "/contract/price", // 절감액 계산.
  CONTRACT_PDF: "/contract/pdf",
  CONTRACT_REVIEW: "/contract/review", //파트너사 계약 동의 미동의 API
  CONTRACT_SURETY: "/contract/surety", // 파트너사 이행증권 업데이트
  CONTRACT_SAME: "/contract/same", // 변경계약 이전계약서 목록

  // CONTRACT_PARTNER_SIGN: $isLocal
  //   ? `${process.env.VUE_APP_LIG_API_ENDPOINT}/ligs/cmmn/sign/contract`
  //   : $isLIG
  //   ? `/ligs/cmmn/sign/contract`
  //   : `/ligs/cmmn/sign/contract`, //파트너사 계약 전자서명 API POST obj = {cid, sigendData}

  // 18. 구매 신청 관리
  PURCHASE: "/purchase",
  PURCHASE_TYPE: `/purchase/type`,
  PURCHASE_REGISTER: "/purchase/register",

  // 20. 검수
  INSPECT: "/inspect",

  // 21. 엑셀
  EXCEL: "/excel",

  // 22. 로그아웃
  LOGOUT: "/logout",

  // 23. 알림 내역 관리
  NOTIFY: "/message",
  NOTIFY_STATUS: "/message/send/type",
  NOTIFY_TYPE: "/message/type",

  // 24. 취급 품목
  HANDLE: "/handleItem", // 품목 리스트
  HANDLE_LINK: "/handleItem/link", // 품목 연결정보 조회
  HANDLE_ITEM_SEARCH: "/handleItem/search",

  // 26. 수행도 평가 관리
  EVALUATE: "/evaluate", // / 수행도 평가 현황 목록
  EVALUATE_CODE: "/evaluate/code", // / 수행도 평가 진행
  EVALUATE_RESULT: "/evaluate/result", // / 수행도 평가 결과 목록
  EVALUATE_PARTNER: "/evaluate/partner", // / 수행도 평가 진행 내역 (파트너 관리)

  DRAFT: "/draft", // 기안등록

  //80. 기업 신용 평가 연동
  CREDIT_SYNC: "/credit/sync",

  //81. 전자문서연동
  SIGN_CONTRACT_DOWNLOAD: "/sign/contract/download",
  //82. 템플릿관리
  TEMPLATE: "/template", // 템플릿 리스트 조회
  TEMPLATE_LIST: "/template/list", // 템플릿 리스트 조회 - 페이징 제거
  TEMPLATE_DELETE: "/template/delete", //템플릿 삭제

  // 90. LIG 커스터마이징 호출
  ALRIM: "/alrim", // [공통] 수동 알림 발송

  /////////////////////////////////////////////////////
  INFO: "/info", // /api/partner/{id}/info 파트너 추가정보 조회
  COPY: "/copy", // /api/post/{id}/copy 공고 재 등록
  CLOSE: "/close", // /api/tender/{id}/close 입찰마감
  OPEN: "/open", // /api/tender/{id}/open 입찰 개봉
  AGREE: "/agree", // /api/order/{id}/agree 발주 동의
  CANCEL: "/cancel", // /api/order/{id}/cancel 발주 발행취소
  BUSINESS: "/business", // /api/partner/{id}/business 파트너 거래현황

  // 27. 정산서관리
  // SETTLEMENT_APPROVAL: `/settlement/approval`, // 정산서 등록관리
  // SETTLEMENT_DETAIL: "/settlement",
  // SETTLEMENT_CONTRACT: "/settlement/contract",
  // SETTLEMENT_ORDER: "/settlement/order",
  // SETTLEMENT_CALCULATE: "/settlement/contractCalculate",
  // SETTLEMENT_USEPROOF: "/settlement/useProof",
  // SETTLEMENT_EXCEL: "/settlement/excel", // 전표 일괄등록
  // SETTLEMENT_PURCHASEHISTORY: "/settlement/purchaseHistory", // 구매요청 정산 이력 조회
  // SETTLEMENT_PURCHASE: "/settlement/purchase", // 구매요청 목록 조회
  // SETTLEMENT_PARTNER: "/settlement/partner", // 거래처 정보 조회 ( 사업자 , 통장 증빙 )

  // 28. 정산서 외부 거래처 조회
  VOUCHER_PARTNER: "/voucher/partner",
  VOUCHER_ASSETS: "/voucher/assets",

  // 28. 정산서 외부 전표 연동
  VOUCHER_ACCOUNCT: "/voucher/account", //계정과목조회
  VOUCHER_CURRENCY: "/voucher/currency", // 통화 조회
  VOUCHER_INFRA_ACCOUNT: "/voucher/infra/account", // 인프라 계정과목 조회
  VOUCHER_INFRA_TAXITEM: "/voucher/taxItem", // 세목 조회
  VOUCHER_TAXRATE: "/voucher/taxRate", // 세율 조회
  VOUCHER_WITHHOLDING: "/voucher/withholding", // 원천세 조회
  VOUCHER_WITHHOLDING_SUB: "/voucher/withholdingSub", // 원천세 하위 분류 조회

  VOUCHER_EXCHANGE_RATE: "/voucher/exchangeRate", // 환율 조회

  // 28. 정산서 외부 조직 연동
  VOUCHER_ORG_DEPARTMENT: "/voucher/org/department", // 부서 조회
  VOUCHER_ORG_BUSINES: "/voucher/org/businessPlaces", // 사업장 조회
  VOUCHER_ORG_SERVICE: "/voucher/service", // 서비스 조회

  // 28. 정산서 외부 증빙자료
  VOUCHER_PROOF_CORPCARD: "/voucher/proof/approval/corpCard", // 법인카드 사용내역 조회
  VOUCHER_PROOF_ETAX: "/voucher/proof/etax", // 세금계산서 조회
  VOUCHER_PROOF_NTS: "/voucher/proof/nts", // 국세청 세금계산서 조회

  DRAFT_ACCOUNT: "/draft/account", // 정산여부 완료/미완료 적용
  DRAFT_PRICE: "/draft/price", // 기안금액 추가금액 등록

  REASON_NON_DEDUCTION: "/reason/nonDeduction", // 불공제사유 코드 조회

  //30.CMDB
  CMDB_CATEGORY: "/cmdb/category", // 품목조회

  ITEM: `/item`,

  // 31. pdf 관리
  PDF_HISTORY: "/pdfHistory",
  PDF_HISTORY_VIEW: "/pdfHistory/view",

  // 32. 검수
  INSPECT_PARTNER: "/inspect/partner", // [공급사]

  //표준검수양식
  KEP_INSPECT_SAMPLEDOWNLOAD: "kep/cmmn/IN/download",

  //표준화 구매관리 - 구매요청
  ST_PURCHASE_REQUEST: "/stPurchaseRequest",
  ST_PURCHASE_REQUEST_UPDATE: "/stPurchaseRequest/update",
  ST_PURCHASE_REQUEST_DRAFT: "/stPurchaseRequest/draft", //기안관리 구매요청불러오기
  ST_PURCHASE_REQUEST_REQUEST: "/stPurchaseRequest/request", //계약/발주 에서 구매요청서 불러오기

  //표준화 구매관리 - 기안관리
  ST_PURCHASE_DRAFT: "/stPurchaseDraft",
  ST_PURCHASE_DRAFT_COMPLETE: "/stPurchaseDraft/completeAprvl",
  ST_PURCHASE_DRAFT_REUSE: "/stPurchaseDraft/reuse", //{draftCid}
  ST_PURCHASE_DRAFT_SETTLEMENTAMOUNT: "/kep/settlement/draft/settlementAmount/", //{draftCid}

  //기안증액 increase
  INCREASE: `/increase`,
  INCREASE_INIT: "/increase/init", //increase/source/{draftCid},
  INCREASE_DRAFT: "/increase/draft", //increase/draft/{draftCid}

  //표준화 견전요청
  ESTIMATE_STATUS: "/estimate/status",
  ESTIMATE: "/estimate",
  ESTIMATE_RE: "/estimate/re",

  //표준화 견적회신
  QUOTATION_STATUS: "/quotation/status",
  QUOTATION: "/quotation",

  //표준화 마감/정산-검수
  ST_INSPECT: "/stInspect",

  //세금계산서 목록
  TRANSACTION: "/transaction",
  TAX_INVOICE: "/tax/invoice/",
  TAX_SCHEDULED_INVOICE: "/tax/scheduled/invoice/",

  //표준화 정산서 불러오기
  SETTLEMENT: "/settlement",
  //표준화 정산서 저장
  SETTLEMENT_REGISTER: "/settlement/register",
  //표준화 견적,공고,계약 + 구매요청정보
  //type(purchase: 구매요청 , estimate : 견적 , post : 공고 , contract : 계약)에 대한 번호의 레퍼런스 정보 리턴한다.
  //stCommon/{type}/{requestCid}
  ST_COMM_REQUEST: "/stCommon",

  // 메뉴별 권한 관리
  ROLE: "/role",
  ROLE_DETAIL: "/role/detail",
  ROLE_ROLE_NAMES: "/role/roleNames",

  // 900. 공통 레퍼런스 정보
  ST_COMMON: "/stCommon",

  // 984. 통계
  STATISTICS_ORDERITEM: "/statistics/orderItem", //발주현황
  STATISTICS_ORDER: "/statistics/order", //발주건별정보

  // 985. 평가
  ST_EVALUATE: "/stEvaluate",
  ST_EVALUATE_EVALUATE: "/stEvaluate/evaluate",
  ST_EVALUATE_EVALUATE_RESULT: "/stEvaluate/evaluateResult",
  ST_EVALUATE_EVALUATE_SITUATION: "/stEvaluate/evaluateSituation",
  ST_EVALUATE_EVALUATE_SITUATE: "/stEvaluate/evaluateSituate",
  ST_EVALUATE_PARTNER: "/stEvaluate/partner",
  ST_EVALUATE_TEMPLATE: "/stEvaluate/template",

  // KEP
  //결재선관리
  KEP_COMM_CODE_DOCTYPE: `/kep/cmmn/code/docType`,
  KEP_COMM_CODE_CONDITION: `/kep/cmmn/code/condition`,
  KEP_COMM_CODE_LINETYPE: `/kep/cmmn/code/lineType`,
  KEP_COMM_CODE_DIVISION: `/kep/cmmn/code/division`,
  KEP_COMM_CODE_DUTYLEVEL: `/kep/cmmn/code/dutyLevel`,

  KEP_COMM_APRVL_LINE: `/kep/commAprvlLine`,
  KEP_COMM_APRVL_CONDITION: `/kep/commAprvlLine/condition`,
  KEP_COMM_APRVL_ACCOUNT: `/kep/commAprvlLine/account`,
  KEP_COMM_APRVL_DRAFTDOCLINE: `/kep/commAprvlLine/draftDocApprovalLine`, //kep/commAprvlLine/draftDocApprovalLine/{targetGubun}/{amount}

  //KEP agit template
  API_AGIT: "/agit", // 아지트 재생성//requestCid
  KEP_AGIT: "/kep/agit",

  //정산서관리
  KEP_SETTLEMENT_NONESETTLEMENTINSPECT: "/kep/settlement/noneSettlementInspect",

  //정산서 공통 API
  KEP_CMMN: "/kep/cmmn", //공통

  KEP_CMMN_ACCOUNT: "/kep/cmmn", //"/kep/cmmn/{acquireType}/Account", //회계게정코드

  KEP_SETTLEMENT: "/kep/settlement",
  KEP_RELATION: "/kep/settlement/relation", ///kep/settlement/relationInspect/{inspectCid}
  KEP_SETTLEMENTAMOUNT: "/kep/settlement/request/settlementAmount", ///kep/settlement/request/settlementAmount/{requestCid}
  KEP_CMMN_SETTLEMENT_STATUS: "/kep/cmmn/settlementStatus", //정산서상태코드
  KEP_CMMN_BANKCODE: "/kep/cmmn/bankCode", //은행코드
  KEP_CMMN_BILLTYPE: "/kep/cmmn/billType", //계산서종류
  KEP_CMMN_CURRENCY: "/kep/cmmn/currency", //통화
  KEP_CMMN_DEDUCTIONTYPE: "/kep/cmmn/deductionType", ////kep/cmmn/deductionType?code={code}", //정산서 매입세액종제유형
  KEP_CMMN_EVIDENCETYPE: "/kep/cmmn/evidenceType", //정산서 증빙유형
  KEP_CMMN_MODTAXBILLCODE: "/kep/cmmn/modTaxBillCode", //세금계산서 수정콛
  KEP_CMMN_TAXBILL: "/kep/cmmn/taxBill", //전자세금계산서 종류
  KEP_CMMN_TAXBILLTYPES: "/kep/cmmn/taxBillTypes", //계산서 종류
  KEP_CMMN_TAXCODE: "/kep/cmmn/taxCode", // 세금코드
  KEP_CMMN_TAXTYPE: "/kep/cmmn/taxType", ///kep/cmmn/taxType?code={code} //정산서 매입세액 상세유형

  /**
   * 00. SAP
   * SAP 사용하는 API
   */
  KEP_SAP: "/kep/sap",
  KEP_SAP_ASSET: "kep/sap/asset",

  /** SAP S-FI-499 자산취득 조회. */
  KEP_SAP_ASSETACQUIRETYPE: "/kep/sap/assetAcquireType",
  /** SAP S-FI-339 자산클래스(자산분류). */
  KEP_SAP_ASSETCLASS: "/kep/sap/assetClass",
  /** SAP S-FI-339 자산클래스(자산분류). */
  KEP_SAP_ASSETSMALLCLASS: "/kep/sap/assetSmallClass",
  /** SAP S-FI-325 사업장달력 조회. */
  KEP_SAP_BIZPLACEHOLIDAY: "/kep/sap/bizPlaceHoliday",
  /** SAP S-CO-003 코스트 센터. */
  KEP_SAP_COSTCENTER: "/kep/sap/costCenter",
  /** SAP S-TX-086 환율정보 조회. [SAP 권한 확인필요] */
  KEP_SAP_EXCHANGERATE: "/KEP/SAP/EXCHANGERATE",
  /** SAP S-FI-497 지급예정일자 조회. */
  KEP_SAP_EXPECTEDPAYMENTDATE: "/kep/sap/expectedPaymentDate",
  /** SAP S-CO-002 관리항목 코드. */
  kep_sap_manageItemCode: "/kep/sap/manageItemCode",
  /** SAP S-FI-307 세금계산서 조회. */
  KEP_SAP_NTSTAXBILL: "/kep/sap/ntsTaxBill",
  /** SAP S-FI-341 거래처 조회. */
  KEP_SAP_PARTNER: "/kep/sap/partner",
  /** SAP S-CO-002 프로젝트 코드. */
  KEP_SAP_PROJECTCODE: "/kep/sap/projectCode",
  /** SAP S-CO-002 서비스 코드. */
  KEP_SAP_SERVICECODE: "/kep/sap/serviceCode",
  /** SAP S-FI-343 세금코드 조회. */
  KEP_SAP_TAX: "/kep/sap/tax", //{code}
  /** SAP S-FI-498 원천세 하위 분류 조회. */
  KEP_SAP_WITHHOLDINGSUBTAX: "/kep/sap/withholdingSubTax",
  /** SAP S-FI-329 원천세코드 조회. */
  KEP_SAP_WITHHOLDINGTAX: "/kep/sap/withholdingTax",
  /** 전표미리보기 */
  KEP_SETTLEMENT_PREVIEW: "/kep/settlement/preview", ///api/kep/settlement/preview/{settlementCid}
  /** SAP 정산서 결재 전표생성. */
  KEP_SETTLEMENT_SLIP: "/kep/settlement/slip", ///api/kep/settlement/slip/{docType}/{ids}

  /** 지출정산 */
  KEP_SAP_EXPENDITURE: "/kep/sap/expenditure",

  //지출정산서 엑셀템플릿
  KEP_SAP_EXPENDITURE_EXCELTEMPLATEDOWNLOAD: "kep/cmmn/ep/download",
  //유무형자사 엑셀템플릿
  KEP_SAP_INFRA_EXCELTEMPLATEDOWNLOAD: "kep/cmmn/if/download",

  /** 인프라 엑셀 업로드 */
  KEP_SAP_EXPENDITURE_EXCELUPLOAD: "kep/sap/expenditure/excelUpload",

  KEP_SAP_EXPENDITURE_EXCELDOWNLOAD: "kep/sap/infra/excelDownload",
  /** SAP S-FI-524 지출정산 계정 입력필드 조회(지출정산 계정과목 조회). */
  //KEP_SAP_EXPENDITURE_{ACQUIRETYPE}_ASSETACQUIRETYPEFIELD : "/kep/sap/expenditure/{acquireType}/assetAcquireTypeField",
  /** SAP S-FI-030 계정 입력필드 조회(지출정산 계정과목 조회). */
  //KEP_SAP_EXPENDITURE_{SETTLEMENTTYPE}_{DRCR}_ACCOUNTFIELD : "/kep/sap/expenditure/{settlementType}/{drcr}/accountField",
  /** SAP S-FI-278 계정과목 조회. */
  KEP_SAP_EXPENDITURE_ACCOUNT: "/kep/sap/expenditure/account",
  /** SAP s-fi-338 지출정산 자산자산장부 조회. */
  KEP_SAP_EXPENDITURE_ASSET: "/kep/sap/expenditure/asset",
  /** SAP S-FI-552 지출정산 계정 별 자산취득 조회.
   * /kep/sap/expenditure/assetAcquireType/{account}
   */
  KEP_SAP_EXPENDITURE_ASSETACQUIRETYPE: "/kep/sap/expenditure/assetAcquireType",
  /** SAP S-CO-152 예산코드 정산정보 조회. */
  KEP_SAP_EXPENDITURE_BUDCODESETTLEMENT: "/kep/sap/expenditure/budCodeSettlement",
  /** 개인법인카드 사용내역 */
  KEP_SAP_EXPENDITURE_PERSONALCORPORATIONCARD: "/kep/sap/expenditure/personalCorporationCard",

  /** SAP S-FI-015 법인카드 정보 송신. */
  KEP_SAP_EXPENDITURE_CORPORATIONCARD: "/kep/sap/expenditure/corporationCard",
  /** SAP S-CO-111 지출정산 통제 점검. */
  KEP_SAP_EXPENDITURE_INFRASETTLEMENTCONTROL: "/kep/sap/expenditure/infraSettlementControl",
  /** SAP S-FI-568 대표 사업장(기안지 서비스정보). */
  KEP_SAP_EXPENDITURE_MASTERBIZPLACE: "/kep/sap/expenditure/masterBizPlace",
  /** SAP S-FI-481 지급방법(PaymentMethod) 조회. */
  KEP_SAP_EXPENDITURE_PAYMENTMETHOD: "/kep/sap/expenditure/paymentMethod",
  /** SAP S-FI-019 공용 법인카드 대여. */
  KEP_SAP_EXPENDITURE_PUBLICCORPORATIONCARD: "/kep/sap/expenditure/publicCorporationCard",
  /**  공용법인카드목록 */
  KEP_SAP_EXPENDITURE_PUBLICCORPORATIONCARD_LIST: "/kep/sap/expenditure/publicCorporationCard/list",
  /** SAP S-FI-527 원천세유형코드 조회. */
  KEP_SAP_EXPENDITURE_WITHHOLDINGTAXTYPE: "/kep/sap/expenditure/withholdingTaxType",
  /** SAP S-FI-327 사업장 조회. */
  KEP_SAP_EXPENDITURE_WORKSPACE: "/kep/sap/expenditure/workspace",

  /**
   * 유무형정산
   */

  /** 인프라 엑셀 업로드 */
  KEP_SAP_INFRA_EXCELUPLOAD: "kep/sap/infra/excelUpload",
  /** SAP S-FI-508 자산취득관리번호 조회. */
  KEP_SAP_INFRA_ASSETACQUIREMANAGEMENTNUMBERS: "/kep/sap/infra/assetAcquireManagementNumbers",
  /** SAP S-FI-317 인사부서-코스트 센터. */
  KEP_SAP_INFRA_DEPTCOSTCENTER: "/kep/sap/infra/deptCostCenter",
  /** SAP S-CO-158 예산코드 코스트 센터. */
  KEP_SAP_INFRA_DRAFTBUDCODE: "/kep/sap/infra/draftBudCode",
  /** SAP S-CO-160 예산코드 서비스 코드. */
  KEP_SAP_INFRA_DRAFTBUDSERVICECODE: "/kep/sap/infra/draftBudServiceCode",
  /** SAP S-TR-210 지로 조회. */
  KEP_SAP_INFRA_GIRO: "/kep/sap/infra/giro",
  /** SAP S-FI-637 사업영역 API 조회. */
  KEP_SAP_INFRA_MASTERBIZPLACE: "/kep/sap/infra/masterBizPlace",
  /** SAP S-FI-032 거래처 은행계좌 조회. */
  KEP_SAP_INFRA_PARTNERBANKACCOUNT: "/kep/sap/infra/partnerBankAccount",
  /** POST SAP S-FI-026 매입세금계산서 XML 파일 적용. */
  KEP_SAP_INFRA_XMLDATA: "/kep/sap/infra/xmlData",
  //ERP 전송
  EXTERNAL_ERP: "/external/erp", ///api/external/erp/{tar}/{refKey}
};

export default apiPath;
