import axios from "axios";
import stdStore from "@/store";
import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";
import { ALERT_ACTION } from "@/store/modules/alert/action";
import LocalStorageManager from "@/LocalStorageManager";
import apiPath from "@/services/apiPath";
import router from "@/router/router";
import routerPath from "@/router/routerPath";

const store = stdStore;
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

//`${process.env.VUE_APP_API_ENDPOINT}/api`,
const config = {
  baseURL: `/api`,

  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  (config) => {
    progressAction(config, store, PROGRESS_START_ACTION);

    checkConfig(config);
    return config;
  },
  (error) => Promise.reject(error),
);

_axios.interceptors.response.use(
  async (response) => {
    const { config, data } = response;
    //console.log('토큰체크', config);
    progressAction(config, store, PROGRESS_END_ACTION);
    if (data && data.code === "401") {
      const newTokenData = await refreshToken();
      //console.log('refreshToken', newTokenData);
      if (!newTokenData || newTokenData.code !== "200") {
        console.log("%c리프레시토큰 페일", "font-size:50px;");
        await onFailRefreshToken(newTokenData);

        return newTokenData;
      }
      //console.log('%c리프레시토큰 업데이트', 'font-size:50px;');
      const newToken = newTokenData.data.token;
      const newRefreshTokenToken = newTokenData.data.refreshToken;

      LocalStorageManager.shared.changeToken(newToken, newRefreshTokenToken);
      //console.log('업데이트전', config.headers.Authorization);
      config.headers.Authorization = `Bearer ${newToken}`;
      //console.log('업데이트후', config.headers.Authorization);

      // const resultRetry = await this.get( url, config, false );
      const resultRetry = await _axios.request(config);
      return resultRetry;
    }
    if (data && data.code === "403") {
      const params = {
        text: `토큰이 만료되었습니다.<br />다시 로그인해주시기 바랍니다.`,
        onClickY() {
          window.location.reload();
        },
      };

      await store.dispatch(ALERT_ACTION.SHOW_ALERT, params);
      LocalStorageManager.shared.clear();
      return;
    }

    return response;
  },
  async (error) => {
    const { config } = error;

    console.error(`Error: ${error.message}`);

    progressAction(config, store, PROGRESS_END_ACTION);
    console.log(error);
    if (error.response.status === 404) {
      const params = {
        text: "404,페이지를 찾을 수 없습니다. 네트워크 상태를 확인해주세요.",
      };
      await store.dispatch(ALERT_ACTION.SHOW_ALERT, params);
    } else if (error.response.status === 500) {
      const params = {
        text: "500,서버에서 오류가 발생했습니다. 관리자에게 문의해주세요.",
      };
      await store.dispatch(ALERT_ACTION.SHOW_ALERT, params);
    }

    return Promise.reject(error);
  },
);

async function refreshToken() {
  const userData = LocalStorageManager.shared.getUserData();

  if (!userData) return null;

  const token = {
    //token: userData.token,
    refreshToken: userData.refreshToken,
  };

  const config = { isRetry: true };

  const result = await axios
    .post(`/api${apiPath.TOKEN}`, token, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
}

function checkConfig(config) {
  const { contentType, isNeedAuth } = config;

  config.headers = {
    "Content-Type": contentType || "application/json",
    // 'X-Request-Com': companyCode,
  };

  if (isNeedAuth === false) return;

  const userData = LocalStorageManager.shared.getUserData();
  if (!userData || !userData.token) return;

  config.headers.Authorization = `Bearer ${userData.token}`;
}

function progressAction(config, store, action) {
  const { method, isShowProgressbar } = config;

  // if (method !== "post" && method !== "put" && method !== "delete") {
  //   return;
  // }

  if (isShowProgressbar === false) {
    return;
  }

  store.dispatch(action);
}

async function onFailRefreshToken(newTokenData) {
  // 토큰 재발급 에러. 일단 이렇게 처리하겠다.
  console.log("onFailRefreshToken :>> ", newTokenData);
  if (newTokenData.code === "401" && newTokenData.text === "토큰 만료") {
    const isAdmin = LocalStorageManager.shared.getIsAdmin();
    alert("로그인 시간이 만료되었습니다.");
    if (isAdmin) {
      window.location.href = "/vmffotvharhksfl";
    } else {
      window.location.href = "/login";
    }
    LocalStorageManager.shared.clear();
  }
}

// Plugin.install = function (app) {
//   //, options
//   app.config.globalProperties.$axios = _axios;
//   window.axios = _axios;
//   Object.defineProperties(app.config.globalProperties, {
//     axios: {
//       get() {
//         return _axios;
//       },
//     },
//     $axios: {
//       get() {
//         return _axios;
//       },
//     },
//   });
// };

export default {
  async install(app) {
    app.config.globalProperties.$axios = _axios;
    window.axios = _axios;
    Object.defineProperties(app.config.globalProperties, {
      axios: {
        get() {
          return _axios;
        },
      },
      $axios: {
        get() {
          return _axios;
        },
      },
    });
  },
};

// export default async ({ app }) => {
//   app.config.globalProperties.$axios = _axios;
//   window.axios = _axios;
//   Object.defineProperties(app.config.globalProperties, {
//     axios: {
//       get() {
//         return _axios;
//       },
//     },
//     $axios: {
//       get() {
//         return _axios;
//       },
//     },
//   });

//   console.log(app);
// };
